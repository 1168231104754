import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  users: User[];
};

export function UnassignedTeamMembers({users}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      {users.length > 0
        ? intl.formatMessage(messages.unassigned, {
            users: intl.formatList(
              users.map((user) => <UserLink key={user.id} user={user} withAvatar={false} colored target='_blank' />),
              {type: 'conjunction', style: 'narrow'},
            ),
          })
        : intl.formatMessage(messages.allAssigned)}
    </div>
  );
}
