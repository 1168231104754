import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Review & assessment cycle import',
    description: '[title]: Assessment cycle import page title',
  },
  annotation: {
    defaultMessage:
      'Create a review cycle by uploading all relevant information using the provided <link>template</link>.',
    description: 'Assessment cycle import annotation',
  },
  wrongFileType: {
    defaultMessage: 'This file type is not supported. Please upload an .xlsx file.',
    description: 'Assessment cycle import error',
  },
  success: {
    defaultMessage: 'Assessment cycle has been created',
    description: 'Assessment cycle import success message',
  },
});
