import {boolean, dict, ExtractSchemaType, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {pick} from '@joomcode/deprecated-utils/object/pick';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';

export enum Feature {
  INVADER_LOGO = 'invaderLogo',
  SALARY_RANGES_ADMIN = 'salaryRangesAdmin',
  SALARY_RANGES_MANAGER = 'salaryRangesManager',
  OFFER_CR = 'offerCR',
  ONE_TIME_BONUSES = 'oneTimeBonuses',
  OWN_ONE_TIME_BONUSES = 'ownOneTimeBonuses',
  OFFICE_POLICY = 'officePolicy',
  JOB_CONTRACT_ON_HOLD = 'jobContractOnHold',
  VPN = 'vpn',
  SUBORDINATE_INFORMATION = 'subordinateInformation',
  SUBORDINATE_INFORMATION_NEW_ICON = 'subordinateInformationNewIcon',
  USERSNAP = 'usersnap',
  COMPENSATION_REVIEW_REQUEST = 'compensationReviewRequest',
  CALENDAR_ISSUES = 'calendarIssues',
  DUTY = 'duty',
  MANAGER_ASSESSMENT = 'managerAssessment',
}

export const storedFeaturesSchema = dict(optional(boolean())).map(
  (parsedObject): Record<Feature, boolean | undefined> => pick(parsedObject, getEnumValues(Feature)),
);

export type StoredFeatures = ExtractSchemaType<typeof storedFeaturesSchema>;
export type Features = {
  [key in keyof StoredFeatures]: boolean;
};
