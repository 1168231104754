import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {CreateOverrideDiff} from 'domain/duty/api/createOverride';
import {ScheduleIssue} from 'domain/duty/model/scheduleIssue';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {User} from 'domain/user/model';
import {dateToString} from 'models/system/formattedDate';
import {IntlShape} from 'react-intl';
import {FormState} from '.';

export const getDate = (date: string, time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const result = new Date(date);

  result.setUTCHours(hours);
  result.setUTCMinutes(minutes);
  result.setUTCSeconds(seconds);

  return result;
};

export const getFormattedDate = (date: string, time: string, intl: IntlShape) =>
  intl.formatDate(getDate(date, time), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'utc',
  });

export const getDiffFromState = (state: FormState): CreateOverrideDiff | undefined => {
  if (!state.slot || !state.startDate || !state.startTime || !state.endDate || !state.endTime) {
    return undefined;
  }

  return {
    slot: state.slot,
    startDate: getDate(state.startDate, state.startTime).toISOString(),
    endDate: getDate(state.endDate, state.endTime).toISOString(),
  };
};

export const getNewScheduleItem = (
  state: FormState,
  issues: ScheduleIssue[],
  teamMembers: User[],
): ScheduleItem | undefined => {
  const startDate = getDate(state.startDate, state.startTime);
  const endDate = getDate(state.endDate, state.endTime);
  const users = state.slot?.userIds.map((id) => teamMembers.find((user) => user.id === id)).filter(isNotNullish);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || !users.length) {
    return undefined;
  }

  return {
    id: undefined,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    slot: {
      id: undefined,
      users,
    },
    issues,
  };
};

export const getDateFromUrlParams = (name: string, search: string): Date | null => {
  const param = new URLSearchParams(search).get(name);
  return param ? new Date(param) : null;
};

export const getInitialDate = (name: string, search: string): string | undefined => {
  return dateToString(getDateFromUrlParams(name, search)) || undefined;
};

export const getInitialTime = (name: string, search: string): string | undefined => {
  const date = getDateFromUrlParams(name, search);

  if (!date) {
    return undefined;
  }

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

type GetLocalTimeProps = {
  time: string;
  dateString: string;
  intl: IntlShape;
};

export const getLocalTime = ({dateString, time, intl}: GetLocalTimeProps): string => {
  const [hours, minutes] = time.split(':').map(Number);

  const date = new Date(dateString);
  date.setUTCHours(hours, minutes);

  const formattedDate = intl.formatDate(date, {year: 'numeric', month: '2-digit', day: '2-digit'});
  const formattedTime = intl.formatTime(date, {hour: '2-digit', minute: '2-digit'});
  const isDateChanged = date.getUTCDate() !== date.getDate();

  return isDateChanged ? `${formattedDate}, ${formattedTime}` : formattedTime;
};
