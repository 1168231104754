import {PageLocker} from '@joomcode/joom-ui/PageLock';
import {Duty} from 'domain/duty/model';
import {DutyDiff} from 'domain/duty/model/diff';
import {HandoffMoment} from 'domain/duty/model/handoffMoment';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {Schedule} from 'domain/duty/model/schedule';
import {updateDutyFx} from 'domain/duty/stores/main';
import {DutyForm} from 'domain/duty/widgets/Form';
import {DutyHandoffMoment} from 'domain/duty/widgets/HandoffMoment';
import {ReactComponent as HandoffMomentIcon} from 'domain/duty/widgets/HandoffMoment/icons/handoffMoment.svg';
import {DutyTimeline} from 'domain/duty/widgets/Timeline';
import {TeamId} from 'domain/team/model/id';
import {User} from 'domain/user/model';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {teamsUrls} from 'routes/teams/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  duty: Duty;
  pageLocker: PageLocker;
  teamId: TeamId;
  teamMembers: User[];
};

export function DutyUpdateForm({duty, pageLocker, teamId, teamMembers}: Props) {
  const intl = useIntl();
  const {id, schedule} = duty;
  const history = useHistory();
  const [newSchedule, setNewSchedule] = useState<Schedule | undefined>();
  const [handoffMoment, setHandoffMoment] = useState<HandoffMoment | undefined>();

  const onScheduleGenerate = useCallback(
    (diff: RotationSettingsDiff, schedule: Schedule) => {
      setHandoffMoment(diff.handoffMoment);
      setNewSchedule(schedule);
    },
    [setNewSchedule],
  );

  const onSubmit = useCallback(
    (state: DutyDiff) => {
      const diff: DutyDiff = state.rotationSettings?.effectiveDate
        ? state
        : {
            ...state,
            rotationSettings: undefined,
          };

      return updateDutyFx({params: {id, teamId}, diff})
        .then(() => {
          pageLocker.unlock();
          history.push(teamsUrls.duties({id: teamId}));
          toaster.success(intl.formatMessage(messages.success, {dutyName: duty.fullName}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [teamId, pageLocker.unlock, history.push, intl],
  );

  return (
    <DutyForm
      duty={duty}
      onScheduleGenerate={onScheduleGenerate}
      onSubmit={onSubmit}
      pageLocker={pageLocker}
      teamMembers={teamMembers}
    >
      <div className={styles.schedule}>
        <DutyTimeline
          {...(newSchedule && {
            duty: {
              title: (
                <div className={styles.title}>
                  {intl.formatMessage(messages.newSchedule)}
                  {handoffMoment && (
                    <>
                      <HandoffMomentIcon />
                      <DutyHandoffMoment handoffMoment={handoffMoment} />
                    </>
                  )}
                </div>
              ),
              mainSchedule: newSchedule.main,
              overrideSchedule: newSchedule.overrides,
            },
          })}
          ghostDuty={{
            title: (
              <div className={styles.title}>
                {intl.formatMessage(messages.currentSchedule)}
                <HandoffMomentIcon />
                <DutyHandoffMoment handoffMoment={duty.rotationSettings.handoffMoment} />
              </div>
            ),
            mainSchedule: schedule.main,
            overrideSchedule: schedule.overrides,
          }}
          timezoneOffset={0}
          teamId={teamId}
        />
      </div>
    </DutyForm>
  );
}
