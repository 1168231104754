import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {UserQuestionnaireStatus} from 'domain/assessment/questionnaire/model/status';
import {formatUserQuestionnaireStatus} from 'domain/assessment/questionnaire/model/status/formatter';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: UserQuestionnaireStatus;
};

const intentByStatus: Record<UserQuestionnaireStatus, BadgeIntent> = {
  [UserQuestionnaireStatus.IN_PROGRESS]: 'primary',
  [UserQuestionnaireStatus.COMPLETED]: 'positive',
  [UserQuestionnaireStatus.NOT_STARTED]: 'warning',
};

export function QuestionnaireStatusBadge({status}: Props) {
  const intl = useIntl();

  return <Badge intent={intentByStatus[status]}>{formatUserQuestionnaireStatus(status, intl)}</Badge>;
}
