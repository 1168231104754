import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Page} from 'components/widgets/Page';
import {useCycles} from 'domain/assessment/cycle/hooks/useCycles';
import {CycleTable} from 'domain/assessment/cycle/widgets/Table';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/assessment/titles';
import {assessmentUrls} from 'routes/assessment/urls';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';

export function CyclesPage() {
  const intl = useIntl();
  const managerAssessmentFeature = useFeature(Feature.MANAGER_ASSESSMENT);
  const {data, dataState} = useCycles();

  if (!managerAssessmentFeature.isAvailable) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.cycles)}
      actions={
        <RouterLinkButton to={assessmentUrls.cycleImport()} size='m' kind='primary' intent='primary'>
          {intl.formatMessage(messages.importButton)}
        </RouterLinkButton>
      }
    >
      <Panel>
        <CycleTable data={data} dataState={dataState} />
      </Panel>
    </Page>
  );
}
