import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const compensationsUrlPatterns = {
  root: '/business-trips',
  requests: '/business-trips/requests',
  request: '/business-trips/requests/:compensationRequestId',
  createRequest: '/business-trips/requests/create',
  report: '/business-trips/requests/:compensationRequestId/report',
};

export const compensationsUrls = {
  root: () => compensationsUrlPatterns.root,
  request: (pathParams: {compensationRequestId: string}, searchParams?: {reported: string}) =>
    addSearchParams(generatePath(compensationsUrlPatterns.request, pathParams), searchParams),
  requests: (searchParams?: {mode?: string}) =>
    addSearchParams(generatePath(compensationsUrlPatterns.requests), searchParams),
  createRequest: () => compensationsUrlPatterns.createRequest,
  report: (pathParams: {compensationRequestId: string}) => generatePath(compensationsUrlPatterns.report, pathParams),
};
