import {array, ExtractSchemaType, object, oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {cycleLiteSchema} from 'domain/assessment/cycle/model/lite';
import {participantRoleSchema} from 'domain/assessment/cycle/model/participantRole';
import {pageEntitySchema} from 'domain/assessment/questionnaire/model/entity/page';
import {questionnaireLiteSchema} from 'domain/assessment/questionnaire/model/lite';
import {UserQuestionnaireStatus} from 'domain/assessment/questionnaire/model/status';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';

export const userQuestionnaireSchema = object({
  accessEndAt: formattedDateSchema,
  accessStartAt: formattedDateSchema,
  cycle: cycleLiteSchema,
  entities: array(pageEntitySchema),
  responder: userSchema,
  responderRole: participantRoleSchema,
  reviewee: userSchema,
  status: oneOfEnum(UserQuestionnaireStatus),
  questionnaire: questionnaireLiteSchema,
});

export type UserQuestionnaire = ExtractSchemaType<typeof userQuestionnaireSchema>;
