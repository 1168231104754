import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';
import React from 'react';
import {useIntl} from 'react-intl';
import {teamsUrls} from 'routes/teams/urls';
import {ReactComponent as PenIcon} from './icons/pen.svg';
import {messages} from './messages';

type Props = {
  dutyId: DutyId;
  teamId: TeamId;
};

export function DutyUpdateButton({dutyId, teamId}: Props) {
  const intl = useIntl();

  return (
    <RouterLinkButton
      size='m'
      kind='text'
      intent='ghost'
      to={teamsUrls.dutyUpdate({id: teamId, dutyId})}
      iconLeft={<PenIcon />}
    >
      {intl.formatMessage(messages.button)}
    </RouterLinkButton>
  );
}
