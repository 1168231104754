import {generatePath} from 'react-router-dom';

export const assessmentUrlPatterns = {
  root: '/assessment',
  cycle: '/assessment/admin/cycles/:id',
  cycles: '/assessment/admin/cycles',
  cycleImport: '/assessment/admin/cycles/import',
  userQuestionnaire: '/assessment/user/questionnaire/:id',
};

export const assessmentUrls = {
  root: () => assessmentUrlPatterns.root,
  cycle: (pathParams: {id: string}) => generatePath(assessmentUrlPatterns.cycle, pathParams),
  cycles: () => assessmentUrlPatterns.cycles,
  cycleImport: () => assessmentUrlPatterns.cycleImport,
  userQuestionnaire: (pathParams: {id: string}) => generatePath(assessmentUrlPatterns.userQuestionnaire, pathParams),
};
