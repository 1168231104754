import {AxiosError} from 'axios';

type ApiResponse<Data> = {
  data: Data;
};

export const mockOnError =
  <MockData>(mocker: () => MockData) =>
  (error: Error | AxiosError): ApiResponse<MockData> => {
    if (window.intranetEnv.APP_ENV === 'local') {
      console.warn('API response has been substituted by mock data. See the error below.'); // eslint-disable-line no-console
      console.error(error); // eslint-disable-line no-console
      return {
        // hack: parse + stringify to turn date values back to strings
        data: JSON.parse(JSON.stringify(mocker())),
      };
    }
    throw error;
  };
