import {DataState} from '@joomcode/deprecated-utils/dataState';
import {UserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import {createStore} from 'effector';
import {getUserQuestionnaireFx} from '.';

type State = {
  byId: Record<string, UserQuestionnaire | undefined>;
  byIdState: Record<string, DataState | undefined>;
};

const initialState: State = {
  byId: {},
  byIdState: {},
};

export const $userQuestionnaires = createStore<State>(initialState)
  .on(getUserQuestionnaireFx, (state, params) => ({
    ...state,
    byIdState: {
      ...state.byIdState,
      [params.answerId]: DataState.LOADING,
    },
  }))
  .on(getUserQuestionnaireFx.fail, (state, {params}) => ({
    ...state,
    byIdState: {
      ...state.byIdState,
      [params.answerId]: DataState.FAILED,
    },
  }))
  .on(getUserQuestionnaireFx.done, (state, {params, result}) => ({
    ...state,
    byId: {
      ...state.byId,
      [params.answerId]: result,
    },
    byIdState: {
      ...state.byIdState,
      [params.answerId]: DataState.LOADED,
    },
  }));
