import {getEnumValues, isEnumValue} from '@joomcode/deprecated-utils/ts-enum';
import {FieldSelect, FieldTextarea} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {CompensationRequestCreationConfig} from 'domain/compensations/request/model';
import {CompensationRequestReason} from 'domain/compensations/request/model/reason';
import {formatCompensationRequestReason} from 'domain/compensations/request/model/reason/formatter';
import {
  getAdditionalInformationHintByReason,
  getAdditionalInformationLabelByReason,
  getEventDatesLabelByReason,
} from 'domain/compensations/request/utils/getMessageByReason';
import {CountryCode} from 'domain/countryCode/model';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {DailyAllowance} from './DailyAllowance';
import {FieldStays} from './FieldStays';
import {fieldLabels, messages} from './messages';
import {MultiDateFormControl} from './MultiDateFormControl';
import styles from './styles.css';

type Props = {
  userCountryCode: CountryCode;
  onSubmit: FormProps<CompensationRequestCreationConfig>['onSubmit'];
};

export const PREPARATION_WORKDAYS = 1;
const POLICY_LINK_DEFAULT = 'https://www.notion.so/joomteam/Business-Trips-ac665b1ec451484d9b835d727bfdffc1';
const POLICY_LINK_BY_COUNTRY = {
  [CountryCode.RU]: 'https://www.notion.so/5da96640737c4e1cbe3f9638d78f55c5',
  [CountryCode.LV]: 'https://www.notion.so/50fc8ec320e243a69ca9a5c6f2b8823d',
};

export function CompensationsRequestForm({userCountryCode, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const isOneDayTripForbidden = useMemo(
    () => [CountryCode.CN, CountryCode.HK].includes(userCountryCode),
    [userCountryCode],
  );
  const reasonToString = useCallback(
    (reason: CompensationRequestReason) => formatCompensationRequestReason(reason, intl),
    [intl],
  );
  const policyLink = (linkText: React.ReactNode) => (
    <StyledExternalLink
      href={POLICY_LINK_BY_COUNTRY[userCountryCode as CountryCode.RU | CountryCode.LV] ?? POLICY_LINK_DEFAULT}
      colored
    >
      {linkText}
    </StyledExternalLink>
  );
  return (
    <Form<CompensationRequestCreationConfig> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid, values, errors}) => (
        <form id={formId} onSubmit={handleSubmit} className={styles.root}>
          <Panel withPadding>
            <p className={styles.notice}>{intl.formatMessage(messages.commonNotice, {link1: policyLink})}</p>
          </Panel>
          <Panel withPadding>
            <div className={styles.info}>
              <FieldSelect<CompensationRequestReason>
                name='reason'
                label={intl.formatMessage(fieldLabels.reason)}
                items={getEnumValues(CompensationRequestReason)}
                itemToString={reasonToString}
                renderItem={reasonToString}
                required
                hint={intl.formatMessage(messages.reasonHint)}
              />
              {isEnumValue(CompensationRequestReason, values.reason) && (
                <>
                  <FieldTextarea
                    name='reasonDetails.additionalInformation'
                    label={getAdditionalInformationLabelByReason(values.reason, intl)}
                    required
                    hint={getAdditionalInformationHintByReason(values.reason, intl)}
                  />
                  {values.reason !== CompensationRequestReason.OTHER && (
                    <MultiDateFormControl
                      name='reasonDetails.eventDates'
                      label={getEventDatesLabelByReason(values.reason, intl)}
                    />
                  )}
                  <FieldTextarea
                    name='comments'
                    label={intl.formatMessage(fieldLabels.comments)}
                    hint={
                      userCountryCode === CountryCode.RU
                        ? intl.formatMessage(messages.detailsHintRussia)
                        : intl.formatMessage(messages.detailsHintNonRussia)
                    }
                  />
                </>
              )}
            </div>
          </Panel>
          <Panel withPadding title={intl.formatMessage(messages.staysTitle)}>
            <div className={styles.staysLayout}>
              <div className={styles.staysLeft}>
                <div className={styles.staysNotice}>
                  <p className={styles.notice}>{intl.formatMessage(messages.staysHint)}</p>
                  {[CountryCode.RU, CountryCode.LV].includes(userCountryCode) && (
                    <p className={styles.notice}>{intl.formatMessage(messages.staysHintExtraStays)}</p>
                  )}
                </div>
                <FieldStays disabled={submitting} isOneDayTripForbidden={isOneDayTripForbidden} />
              </div>
              <div className={styles.staysRight}>
                <DailyAllowance stayValues={values.stays} validationErrors={errors?.stays} />
              </div>
            </div>
          </Panel>
          <SubmitFormPanel>
            <Button
              form={formId}
              size='l'
              kind='primary'
              intent='primary'
              type='submit'
              disabled={invalid}
              loading={submitting}
            >
              {intl.formatMessage(messages.submitButton)}
            </Button>
          </SubmitFormPanel>
        </form>
      )}
    </Form>
  );
}
