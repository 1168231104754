import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDateRange} from '@joomcode/joom-ui/HumanDate';
import {ListedCycle} from 'domain/assessment/cycle/model/listed';
import {formatCycleType} from 'domain/assessment/cycle/model/type/formatter';
import {CycleLink} from 'domain/assessment/cycle/widgets/Link';
import {CycleMenu} from 'domain/assessment/cycle/widgets/Menu';
import {ParticipantsCountList} from 'domain/assessment/cycle/widgets/ParticipantsCountList';
import {CycleStatusBadge} from 'domain/assessment/cycle/widgets/StatusBadge';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

type CycleColumn = Column<ListedCycle> & {id: ColumnId};
type Props = {
  data: ListedCycle[];
  dataState: DataState;
};

const getRowKey = ({id}: ListedCycle) => id;

export function CycleTable({data, dataState}: Props) {
  const intl = useIntl();
  const columns = useMemo<CycleColumn[]>(() => {
    return [
      {
        defaultWidth: 250,
        id: ColumnId.NAME,
        name: intl.formatMessage(columnNames[ColumnId.NAME]),
        render: (cycle) => <CycleLink cycle={cycle} />,
      },
      {
        defaultWidth: 183,
        id: ColumnId.TYPE,
        name: intl.formatMessage(columnNames[ColumnId.TYPE]),
        render: ({type}) => formatCycleType(type, intl),
      },
      {
        defaultWidth: 210,
        id: ColumnId.DATES,
        name: intl.formatMessage(columnNames[ColumnId.DATES]),
        render: ({startCycleAt, endCycleAt}) => (
          <HumanDateRange range={{from: startCycleAt, to: endCycleAt}} format='short' utc />
        ),
      },
      {
        defaultWidth: 117,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status}) => <CycleStatusBadge status={status} />,
      },
      {
        defaultWidth: 354,
        id: ColumnId.PARTICIPANTS,
        name: intl.formatMessage(columnNames[ColumnId.PARTICIPANTS]),
        render: ({participantCounts}) => <ParticipantsCountList participantCounts={participantCounts} />,
      },
      {
        defaultWidth: 86,
        id: ColumnId.ACTIONS,
        name: intl.formatMessage(columnNames[ColumnId.ACTIONS]),
        render: (cycle) => <CycleMenu cycle={cycle} />,
      },
    ];
  }, [intl]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={data}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName='assessment.cycles.list'
    />
  );
}
