import {ParticipantCount} from 'domain/assessment/cycle/model/participantCount';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  participantCounts: ParticipantCount[];
};

export function ParticipantsCountList({participantCounts}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      {participantCounts.map(({role, count}, index) => (
        <React.Fragment key={role.id}>
          <span className={styles.role}>
            {intl.formatMessage(messages.participants, {
              count,
              formattedCount: intl.formatNumber(count),
              roleName: role.name,
              emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
              isReviewee: role.isReviewee,
            })}
            {index < participantCounts.length - 1 && ','}
          </span>{' '}
        </React.Fragment>
      ))}
    </div>
  );
}
