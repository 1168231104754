import {Button} from '@joomcode/joom-ui/Button';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as WarningIcon} from '@joomcode/joom-ui/icons/core/warningTriangle.svg';
import {PageLocker} from '@joomcode/joom-ui/PageLock';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  date: number;
  isOpen: boolean;
  onClose: () => void;
  pageLocker: PageLocker;
};

export function DutyPendingChangeDialog({date, isOpen, onClose, pageLocker}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const handleGoBackClick = useCallback(() => {
    pageLocker.unlock();
    history.goBack();
  }, [history.goBack, pageLocker.unlock]);

  return (
    <Dialog ariaLabel='some' isOpen={isOpen} onClose={onClose} width='37.5rem'>
      <Dialog.Body withDefaultPadding>
        <div className={styles.root}>
          <div className={styles.warning}>
            <div className={styles.icon}>
              <WarningIcon />
            </div>
          </div>
          <h1 className={styles.title}>{intl.formatMessage(messages.title)}</h1>
          <div className={styles.text}>
            {intl.formatMessage(messages.text, {
              br: <br />,
              date: <HumanDate value={date} format='short' utc />,
            })}
          </div>
          <div className={styles.buttonContinue}>
            <Button intent='primary' kind='primary' size='l' onClick={onClose}>
              {intl.formatMessage(messages.buttonContinue)}
            </Button>
          </div>
          <Button intent='ghost' kind='primary' size='l' onClick={handleGoBackClick}>
            {intl.formatMessage(messages.buttonBack)}
          </Button>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
