import {SlotDiff} from 'domain/duty/model/slot/diff';
import {User} from 'domain/user/model';
import orderBy from 'lodash/orderBy';

export const getRotationMembers = (teamMembers: User[], unassignedTeamMembers: User[]) => {
  const unassignedTeamMembersIds = unassignedTeamMembers.map((user) => user.id);
  return orderBy(teamMembers, (user: User) => !unassignedTeamMembersIds.includes(user.id));
};

export const getUnassignedTeamMembers = (teamMembers: User[], rotation: SlotDiff[] | undefined): User[] => {
  if (!rotation) {
    return [];
  }

  const assignedUserIds = rotation.flatMap((slot) => slot.userIds);
  return teamMembers.filter((user) => !assignedUserIds.includes(user.id));
};

export const filterDate = (weekday: number | undefined) => (date: Date) =>
  weekday === undefined || date.getDay() === weekday;
