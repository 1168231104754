import {UserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {NavigationContext} from './context';

type Props = PropsWithChildren<{
  questionnaire: UserQuestionnaire;
}>;

export function QuestionnaireNavigationProvider({children, questionnaire}: Props) {
  const pages = useMemo(() => questionnaire.entities.map(({data}) => data), [questionnaire]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const currentPage = useMemo(() => pages[currentPageNumber], [currentPageNumber, pages]);
  const isFirstPage = useMemo(() => currentPageNumber === 0, [currentPageNumber]);
  const isLastPage = useMemo(() => currentPageNumber === pages.length - 1, [currentPageNumber, pages]);

  const setNextPage = useCallback(() => {
    if (currentPageNumber < pages.length - 1) {
      setCurrentPageNumber(currentPageNumber + 1);
    }
  }, [currentPageNumber, pages]);

  const setPreviousPage = useCallback(() => {
    if (currentPageNumber > 0) {
      setCurrentPageNumber(currentPageNumber - 1);
    }
  }, [currentPageNumber, pages]);

  useEffect(() => {
    setCurrentPageNumber(0);
  }, [pages]);

  const navigation = useMemo(() => {
    return {
      currentPage,
      currentPageNumber,
      isFirstPage,
      isLastPage,
      pages,
      setNextPage,
      setPreviousPage,
    };
  }, [currentPage, currentPageNumber, isFirstPage, isLastPage, pages, setNextPage, setPreviousPage]);

  return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>;
}
