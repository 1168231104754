import {ValidationResult} from '@joomcode/joom-form';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {FormState} from '.';
import {getDate} from './utils';

export enum DutyOverrideFormError {
  OVERLAPPING_OVERRIDE = 'overlappingOverride',
}

type ValidatorOptions = {
  overrides: ScheduleItem[];
};

export const validateOverrideForm = (
  values: Partial<FormState>,
  {overrides}: ValidatorOptions,
): Partial<
  Record<
    string,
    | ValidationResult<DutyOverrideFormError, Record<string, unknown>>
    | Promise<ValidationResult<DutyOverrideFormError, Record<string, unknown>>>
  >
> => {
  if (values.startDate && values.endDate && values.startTime && values.endTime) {
    const startDate = getDate(values.startDate, values.startTime);
    const endDate = getDate(values.endDate, values.endTime);

    if (
      overrides.some((override) => {
        const overrideStartDate = new Date(override.startDate);
        const overrideEndDate = new Date(override.endDate);

        return startDate < overrideEndDate && endDate > overrideStartDate;
      })
    ) {
      return {
        overlapping: {code: DutyOverrideFormError.OVERLAPPING_OVERRIDE},
      };
    }
  }

  return {
    overlapping: undefined,
  };
};
