import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No cycles found',
    description: 'Assessment cycles table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load assessment cycles',
    description: 'Assessment cycles table: error',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.NAME]: {
    defaultMessage: 'Cycle name',
    description: 'Assessment cycles table: column name',
  },
  [ColumnId.TYPE]: {
    defaultMessage: 'Type',
    description: 'Assessment cycles table: column name',
  },
  [ColumnId.DATES]: {
    defaultMessage: 'Dates',
    description: 'Assessment cycles table: column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Assessment cycles table: column name',
  },
  [ColumnId.PARTICIPANTS]: {
    defaultMessage: 'Participants',
    description: 'Assessment cycles table: column name',
  },
  [ColumnId.ACTIONS]: {
    defaultMessage: 'Actions',
    description: 'Assessment cycles table: column name',
  },
});
