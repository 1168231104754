import {Page} from 'domain/assessment/questionnaire/model/entity/page';
import {createContext} from 'react';

export type QuestionnaireNavigation = {
  currentPage: Page | undefined;
  currentPageNumber: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  pages: Page[];
  setNextPage: () => void;
  setPreviousPage: () => void;
};

export const NavigationContext = createContext<QuestionnaireNavigation | undefined>(undefined);
