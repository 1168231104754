import {Panel} from '@joomcode/joom-ui/Panel';
import {Timing} from 'domain/assessment/common/widgets/Timing';
import {CycleLite} from 'domain/assessment/cycle/model/lite';
import {formatCycleType} from 'domain/assessment/cycle/model/type/formatter';
import {CycleStatusBadge} from 'domain/assessment/cycle/widgets/StatusBadge';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  cycle: CycleLite;
};

export function CycleMetaPanel({cycle: {type, status, startCycleAt, endCycleAt}}: Props) {
  const intl = useIntl();

  return (
    <Panel withPadding>
      <div className={styles.root}>
        <div className={styles.item}>{formatCycleType(type, intl)}</div>
        <div className={styles.item}>
          <CycleStatusBadge status={status} />
        </div>
        <div className={styles.item}>
          <Timing start={startCycleAt} end={endCycleAt} />
        </div>
      </div>
    </Panel>
  );
}
