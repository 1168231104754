import {createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {ListedCycle} from 'domain/assessment/cycle/model/listed';
import {getAllCyclesFx, getCycleFx} from '.';

export const $cycles = createEntityStore({
  getEntityId: (cycle: ListedCycle) => cycle.id,
  loadByIdFx: getCycleFx,
  query: {
    fx: getAllCyclesFx,
    getIdsFromParams: () => [],
  },
});
