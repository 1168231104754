import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import faker from 'faker';
import {QuestionnaireLite} from '.';

export function mockQuestionnaireLite(data: Partial<QuestionnaireLite> = {}): QuestionnaireLite {
  return {
    name: faker.lorem.words(3),
    id: createMockId(),
    description: faker.lorem.sentences(),
    ...data,
  };
}
