import {noop} from '@joomcode/deprecated-utils/function';
import {Menu} from '@joomcode/joom-ui/Menu';
import {MenuButton} from 'components/ui/MenuButton';
import {CycleLite} from 'domain/assessment/cycle/model/lite';
import {CycleStatus} from 'domain/assessment/cycle/model/status';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  cycle: CycleLite;
};

export function CycleMenu({cycle: {status}}: Props) {
  const intl = useIntl();

  return (
    <Menu ariaLabel={intl.formatMessage(messages.menuAriaLabel)} disclosure={<MenuButton />}>
      {(status === CycleStatus.ONGOING || status === CycleStatus.COMPLETED) && (
        <Menu.Item onClick={noop}>{intl.formatMessage(messages.download)}</Menu.Item>
      )}
      {status === CycleStatus.ONGOING && <Menu.Item onClick={noop}>{intl.formatMessage(messages.complete)}</Menu.Item>}
      {status !== CycleStatus.ARCHIVED && <Menu.Item onClick={noop}>{intl.formatMessage(messages.archive)}</Menu.Item>}
      <Menu.Item onClick={noop}>{intl.formatMessage(messages.export)}</Menu.Item>
    </Menu>
  );
}
