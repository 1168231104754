import {Page} from 'components/widgets/Page';
import {CycleImportPanel} from 'domain/assessment/cycle/widgets/ImportPanel';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/assessment/titles';
import {assessmentUrls} from 'routes/assessment/urls';
import {Feature, useFeature} from 'services/features';

export function CycleImportPage() {
  const intl = useIntl();
  const managerAssessmentFeature = useFeature(Feature.MANAGER_ASSESSMENT);

  if (!managerAssessmentFeature.isAvailable) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.cycleImport)}
      breadcrumbs={[{title: intl.formatMessage(pageTitles.cycles), href: assessmentUrls.cycles()}]}
    >
      <CycleImportPanel />
    </Page>
  );
}
