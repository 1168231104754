import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {RoleBadge} from 'domain/role/widgets/Badge';
import {RoleBinding} from 'domain/roleBinding/model';
import {RoleBindingActions} from 'domain/roleBinding/widgets/Actions';
import {RoleBindingPropagationIcon} from 'domain/roleBinding/widgets/PropagationIcon';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TeamPath} from 'domain/team/widgets/Path';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

type RoleBindingColumn = Column<RoleBinding> & {id: ColumnId};
type Props = {
  roleBindings: RoleBinding[];
  dataState: DataState;
  pagination: OffsetPagination;
  count: number;
  columnIds: ColumnId[];
  canManage: boolean;
};

const getRowKey = ({id}: RoleBinding) => id;

export function ResourceRoleBindingsTable({roleBindings, dataState, pagination, count, columnIds, canManage}: Props) {
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const columns = useMemo(() => {
    const allColumns: RoleBindingColumn[] = [
      {
        defaultWidth: 6,
        id: ColumnId.ROLE,
        name: intl.formatMessage(columnNames[ColumnId.ROLE]),
        render: ({role}) => <RoleBadge role={role} />,
      },
      {
        defaultWidth: 16,
        id: ColumnId.TEAM,
        name: intl.formatMessage(columnNames[ColumnId.TEAM]),
        render: ({role, propagate, teamWithAncestors: {team, teamAncestors}}) => (
          <>
            <TeamPath team={team} teamAncestors={teamAncestors} colored />
            {propagate && <RoleBindingPropagationIcon team={team} role={role} />}
          </>
        ),
      },
      {
        defaultWidth: 6,
        id: ColumnId.CREATED_BY,
        name: intl.formatMessage(columnNames[ColumnId.CREATED_BY]),
        render: ({createdBy}) => <UserLink user={createdBy} withAvatar={false} colored />,
      },
      {
        defaultWidth: 8,
        id: ColumnId.ACTIONS,
        name: intl.formatMessage(columnNames[ColumnId.ACTIONS]),
        render: (roleBinding) => (
          <RoleBindingActions roleBinding={roleBinding} selfUserId={selfUserId} canManage={canManage} />
        ),
      },
    ];

    return allColumns.filter(({id}) => columnIds.includes(id));
  }, [intl, columnIds, selfUserId]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={roleBindings}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName='resource.roleBindings.list'
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(count / pagination.limit)}
        />
      }
    />
  );
}
