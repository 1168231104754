import {withAuth} from 'hocs/withAuth';
import {withVpn} from 'hocs/withVpn';
import {RegularLayout} from 'layouts/Regular';
import {CyclePage} from 'pages/Cycle';
import {CycleImportPage} from 'pages/CycleImport';
import {CyclesPage} from 'pages/Cycles';
import {NotFoundPage} from 'pages/NotFound';
import {UserQuestionnairePage} from 'pages/UserQuestionnaire';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {assessmentUrlPatterns, assessmentUrls} from './urls';

export const config: RouteConfig = {
  path: assessmentUrlPatterns.root,
  component: withVpn(withAuth(RegularLayout), NotFoundPage),
  routes: [
    {
      exact: true,
      component: () => <Redirect to={{pathname: assessmentUrls.cycles()}} />,
      path: assessmentUrlPatterns.root,
    },
    {
      component: CyclesPage,
      exact: true,
      path: assessmentUrlPatterns.cycles,
    },
    {
      component: CycleImportPage,
      exact: true,
      path: assessmentUrlPatterns.cycleImport,
    },
    {
      component: CyclePage,
      exact: true,
      path: assessmentUrlPatterns.cycle,
    },
    {
      component: UserQuestionnairePage,
      exact: true,
      path: assessmentUrlPatterns.userQuestionnaire,
    },
    notFoundRouteConfig,
  ],
};
