import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CycleStatus} from 'domain/assessment/cycle/model/status';
import {CycleType} from 'domain/assessment/cycle/model/type';
import {formattedDateSchema} from 'models/system/formattedDate';

export const cycleIdSchema = idSchema;
export const cycleLiteSchema = object({
  createdAt: formattedDateSchema,
  endCycleAt: formattedDateSchema,
  id: cycleIdSchema,
  name: string(),
  startCycleAt: formattedDateSchema,
  status: oneOfEnum(CycleStatus),
  type: oneOfEnum(CycleType),
});

export type CycleId = ExtractSchemaType<typeof cycleIdSchema>;
export type CycleLite = ExtractSchemaType<typeof cycleLiteSchema>;
