import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {UserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import {getUserQuestionnaireFx} from 'domain/assessment/questionnaire/stores/main';
import {$userQuestionnaires} from 'domain/assessment/questionnaire/stores/main/state';
import {Language} from 'domain/language/model';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: UserQuestionnaire | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
};

export function useUserQuestionnaire(answerId: string | undefined, languageCode = Language.EN): ReturnShape {
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $userQuestionnaires,
    keys: [answerId],
    fn: ({byId}, [id]) => (id && byId[id]) || null,
  });
  const dataState = useStoreMap({
    store: $userQuestionnaires,
    keys: [answerId],
    fn: ({byIdState}, [id]) => (id && byIdState[id]) || DataState.IDLE,
  });

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (answerId && !isLoadingOrLoaded(dataState)) {
      getUserQuestionnaireFx({answerId, languageCode}).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }
  }, [answerId, setClientErrorStatus]);

  return {
    clientErrorStatus,
    dataState,
    data,
  };
}
