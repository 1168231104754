import {Timeline, useScrollbox} from '@joomcode/joom-ui/Timeline';
import React, {useMemo, useState} from 'react';
import {Controls} from './Controls';
import {useDutyItems} from './hooks/useDutyItems';
import {useGroups} from './hooks/useGroups';
import {useInitialScroll} from './hooks/useInitialScroll';
import {useTimelineBoundary} from './hooks/useTimelineBoundary';
import {useTimelineUnit} from './hooks/useTimelineUnit';
import styles from './styles.css';
import {
  DutyTimelineSize,
  Severity,
  type DutyTimelineProps,
  type Schema,
  type TimelineItem,
  type TimelineItemWithoutGroup,
} from './types';
import {getIssueIconItems} from './utils/getIssueIconItems';
import {getIssueStripeItems} from './utils/getIssueStripeItems';

export {DutyTimelineProps} from './types';

function useGroupItems<T extends keyof Schema>(items: TimelineItemWithoutGroup<T>[], group: T): TimelineItem<T>[] {
  return useMemo(() => {
    return items.map((item) => ({...item, group}) as TimelineItem<T>);
  }, [items, group]);
}

export function DutyTimeline(props: DutyTimelineProps) {
  const {controls = {}, duty, ghostDuty, children, timezoneOffset, initialScrollDate} = props;
  const dutyMainSchedule = useMemo(() => duty?.mainSchedule || [], [duty?.mainSchedule]);
  const dutyOverrideSchedule = useMemo(() => duty?.overrideSchedule || [], [duty?.overrideSchedule]);
  const ghostDutyMainSchedule = useMemo(() => ghostDuty?.mainSchedule || [], [ghostDuty?.mainSchedule]);
  const {
    sizes = [DutyTimelineSize.WEEK, DutyTimelineSize.MONTH, DutyTimelineSize.QUARTER],
    defaultSize = DutyTimelineSize.MONTH,
  } = controls;
  const [size, setSize] = useState(defaultSize);
  const unit = useTimelineUnit(size);
  const boundaries = useTimelineBoundary(duty ? dutyMainSchedule : ghostDutyMainSchedule);
  const [ref, scrollboxApi] = useScrollbox<HTMLDivElement>();
  const groups = useGroups(props, unit);
  const combinedSchedule = useMemo(
    () => [...dutyMainSchedule, ...ghostDutyMainSchedule],
    [dutyMainSchedule, ghostDutyMainSchedule],
  );
  const scrollOnInit = useInitialScroll(scrollboxApi, combinedSchedule, initialScrollDate);

  const items = [
    ...useDutyItems('duty', dutyMainSchedule),
    ...useDutyItems('ghostDuty', ghostDutyMainSchedule),
    ...useDutyItems('override', dutyOverrideSchedule),
    ...useGroupItems(getIssueIconItems(dutyMainSchedule), 'issueIcon'),
    ...useGroupItems(getIssueStripeItems(dutyMainSchedule, timezoneOffset), 'issueStripe'),
  ];

  const [start, end] = boundaries;

  return (
    <section className={styles.DutyTimeline}>
      <header className={styles.header}>
        {children ? <div>{children}</div> : null}
        <Controls scrollboxApi={scrollboxApi} size={size} sizes={sizes} onSizeChange={setSize} />
      </header>
      <Timeline<Schema>
        items={items}
        start={start}
        end={end}
        unit={unit}
        ref={ref}
        groups={groups}
        offset={timezoneOffset}
        scrollOnInit={scrollOnInit}
      />
    </section>
  );
}

DutyTimeline.Size = DutyTimelineSize;
DutyTimeline.Severity = Severity;
