import {intranetApi} from 'apiClient';
import {mockOnError} from 'apiClient/mockOnError';
import {UserQuestionnaire, userQuestionnaireSchema} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import {mockUserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire/mock';
import {Language} from 'domain/language/model';

export type GetUserQuestionnaireParams = {
  answerId: string;
  languageCode: Language;
};

export const get = async (params: GetUserQuestionnaireParams): Promise<UserQuestionnaire> => {
  const {data} = await intranetApi
    .get('/v1/assessment/user/questionnaire/get', {params})
    .catch(mockOnError(mockUserQuestionnaire));

  return userQuestionnaireSchema.runWithException(data);
};
