import {NavigationContext} from 'domain/assessment/questionnaire/widgets/Navigation/context';
import {useContext} from 'react';

export function useQuestionnaireNavigation() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useQuestionnaireNavigation must be used within a QuestionnaireNavigationProvider');
  }
  return context;
}
