import {DataFilter, DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {DataFilterLoadingError} from 'components/widgets/DataFilterLoadingError';
import {
  AllStockOptionHoldersFilters,
  SubordinateStockOptionHoldersFilters,
} from 'domain/stockOption/holder/model/filters';
import {useUserFilter} from 'domain/user/hooks/useUserFilter';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  config: DataFilterConfig<AllStockOptionHoldersFilters | SubordinateStockOptionHoldersFilters>;
  values: AllStockOptionHoldersFilters | SubordinateStockOptionHoldersFilters;
  onChange(payload: AllStockOptionHoldersFilters | SubordinateStockOptionHoldersFilters): void;
};

export function StockOptionHoldersFilters({config, values, onChange}: Props) {
  const intl = useIntl();
  const {dataState, userFilter} = useUserFilter(intl.formatMessage(messages.userId));
  const filtersConfig = useMemo<DataFilterConfig<AllStockOptionHoldersFilters>>(
    () => ({
      userId: userFilter,
      ...config,
    }),
    [intl, userFilter],
  );

  return (
    <StateHandler
      data={filtersConfig}
      state={dataState}
      renderPlaceholder={() => <DataFilterSpinner />}
      renderError={() => <DataFilterLoadingError />}
    >
      {(fullConfig) => <DataFilter config={fullConfig} onChange={onChange} values={values} />}
    </StateHandler>
  );
}
