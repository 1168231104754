import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  label: {
    defaultMessage: 'Fast options',
    description: 'Duty override form: fast options label',
  },
  oneHour: {
    defaultMessage: 'For 1 hour',
    description: 'Duty override form: fast option',
  },
  threeHours: {
    defaultMessage: 'For 3 hours',
    description: 'Duty override form: fast option',
  },
  untilMorning: {
    defaultMessage: 'Until 09:00 UTC tomorrow',
    description: 'Duty override form: fast option',
  },
  untilCurrentShift: {
    defaultMessage: 'Until the current shift ends',
    description: 'Duty override form: fast option',
  },
});
