import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {DutyPanel} from 'domain/duty/widgets/Panel';
import {useTeamAcl} from 'domain/team/hooks/useTeamAcl';
import {useTeamBreadcrumbs} from 'domain/team/hooks/useTeamBreadcrumbs';
import {useTeams} from 'domain/team/hooks/useTeams';
import {teamIdSchema} from 'domain/team/model/id';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/teams/titles';
import {teamsUrls} from 'routes/teams/urls';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';

export function DutiesPage() {
  const intl = useIntl();
  const {id} = useTypedParams({id: teamIdSchema});
  const {byId: teamById, dataState: teamsDataState} = useTeams();
  const {isTeamOwner, dataState: ownershipDataState} = useTeamAcl(id);
  const dataState = getConsolidatedDataState(teamsDataState, ownershipDataState);
  const breadcrumbs = useTeamBreadcrumbs({id, teamById, includeCurrentTeam: true});
  const dutyFeature = useFeature(Feature.DUTY);

  if (!dutyFeature.isAvailable || !id || (dataState === DataState.LOADED && !teamById[id])) {
    return <NotFoundPage />;
  }

  return (
    <PageStateHandler data={teamById} state={dataState}>
      {() => {
        const team = teamById[id];
        const teamName = team?.name;
        const duties = team?.duties ?? [];

        if (!team || duties.length === 0) {
          return <NotFoundPage />;
        }

        return (
          <Page
            title={intl.formatMessage(pageTitles.duties, {teamName})}
            breadcrumbs={breadcrumbs}
            actions={
              isTeamOwner && (
                <RouterLinkButton
                  to={teamsUrls.dutyCreate({id})}
                  size='m'
                  kind='primary'
                  intent='primary'
                  iconLeft={<PlusIcon />}
                >
                  {intl.formatMessage(messages.createButton)}
                </RouterLinkButton>
              )
            }
          >
            {duties.map((duty) => (
              <DutyPanel key={duty.id} duty={duty} team={team} />
            ))}
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
