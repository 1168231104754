import {constant, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';

export const descriptionSchema = object({
  backgroundColor: optional(string()),
  emoji: optional(string()),
  text: string(),
});

export const descriptionEntitySchema = object({
  type: constant(EntityType.DESCRIPTION),
  data: descriptionSchema,
});

export type Description = ExtractSchemaType<typeof descriptionSchema>;
export type DescriptionEntity = ExtractSchemaType<typeof descriptionEntitySchema>;
