import {approveAsAdmin} from './approveAsAdmin';
import {approveAsManager} from './approveAsManager';
import {cancel} from './cancel';
import {close} from './close';
import {create} from './create';
import {getAll} from './getAll';
import {getById} from './getById';
import {reject} from './reject';
import {updateCommentFromAdmin} from './updateCommentFromAdmin';

export const compensationRequestsApi = {
  approveAsAdmin,
  approveAsManager,
  cancel,
  close,
  create,
  getAll,
  getById,
  reject,
  updateCommentFromAdmin,
};
