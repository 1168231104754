import {intranetApi} from 'apiClient';
import {Duty, dutySchema} from 'domain/duty/model';
import {DutyDiff} from 'domain/duty/model/diff';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';

type UpdateDutyParams = {
  id: DutyId;
  teamId: TeamId;
};

export type UpdateDutyConfig = {
  diff: DutyDiff;
  params: UpdateDutyParams;
};

export const update = async ({diff, params}: UpdateDutyConfig): Promise<Duty> => {
  const {data} = await intranetApi.post('/v1/teams/duties/update', diff, {params});

  return dutySchema.runWithException(data);
};
