import {UniqueIdentifier} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/core/cross.svg';
import {User} from 'domain/user/model';
import {FieldUsers, FieldUsersValue} from 'domain/user/widgets/FieldMultiple';
import React from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as DraggableIcon} from './icons/draggable.svg';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  id: UniqueIdentifier;
  initialValue?: FieldUsersValue;
  name: string;
  onRemove?: () => void;
  users?: User[];
  validate?: ValidationFunction<FieldUsersValue, ValidationOptions, unknown>;
};

export function SortableItem({id, initialValue, name, onRemove, users, validate}: Props) {
  const intl = useIntl();
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} className={styles.field} style={style} {...attributes}>
      <div className={styles.dragHandle} {...listeners}>
        <DraggableIcon />
      </div>
      <div className={styles.input}>
        <FieldUsers
          name={name}
          id={String(id)}
          placeholder={intl.formatMessage(messages.placeholder)}
          users={users}
          validate={validate}
          initialValue={initialValue}
        />
      </div>
      {onRemove && (
        <div className={styles.removeButton}>
          <Button
            aria-label={intl.formatMessage(messages.removeButton)}
            iconLeft={<CrossIcon />}
            size='m'
            kind='text'
            intent='neutral'
            type='button'
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  );
}
