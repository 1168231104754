import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {FieldInput, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {PageLock, PageLocker} from '@joomcode/joom-ui/PageLock';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {MarkdownCheatsheetLink} from 'components/ui/MarkdownCheatsheetLink';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {dutyApi} from 'domain/duty/api';
import {usePendingChange} from 'domain/duty/hooks/usePendingChange';
import {Duty} from 'domain/duty/model';
import {DutyDiff} from 'domain/duty/model/diff';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {Schedule} from 'domain/duty/model/schedule';
import {getDataForGeneratingSchedule} from 'domain/duty/utils/getDataForGeneratingSchedule';
import {FieldRotation} from 'domain/duty/widgets/FieldRotation';
import {FieldSlackUserGroups} from 'domain/duty/widgets/FieldSlackUserGroups';
import {FieldTime} from 'domain/duty/widgets/FieldTime';
import {FieldTimeZone} from 'domain/duty/widgets/FieldTimeZone';
import {FieldWeekday} from 'domain/duty/widgets/FieldWeekday';
import {LocalHandoffTime} from 'domain/duty/widgets/LocalHandoffTime';
import {DutyPendingChangeCallout} from 'domain/duty/widgets/PendingChangeCallout';
import {DutyPendingChangeDialog} from 'domain/duty/widgets/PendingChangeDialog';
import {UnassignedTeamMembers} from 'domain/duty/widgets/UnassignedTeamMembers';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {User} from 'domain/user/model';
import {FieldUser} from 'domain/user/widgets/Field';
import isEqual from 'lodash/isEqual';
import pDebounce from 'p-debounce';
import React, {PropsWithChildren, useMemo, useState} from 'react';
import {Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';
import styles from './styles.css';
import {filterDate, getRotationMembers, getUnassignedTeamMembers} from './utils';

const today = new Date();

type Props = PropsWithChildren<{
  duty?: Duty;
  onScheduleGenerate: (diff: RotationSettingsDiff, schedule: Schedule) => void;
  onSubmit: (diff: DutyDiff) => Promise<void>;
  pageLocker: PageLocker;
  teamMembers: User[];
}>;

export function DutyForm({children, duty, onScheduleGenerate, onSubmit, pageLocker, teamMembers}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const selfUserId = useSelfUserId();
  const formId = useMemo(uuid, []);
  const timeZoneOffset = useMemo(() => today.getTimezoneOffset(), []);
  const [showEffectiveDate, setShowEffectiveDate] = useState(!duty);
  const {pendingChangeDate, showPendingChangeDialog, onPendingChangeDialogClose} = usePendingChange(duty);

  const generateSchedule = useAsyncTask<[RotationSettingsDiff], void>(
    pDebounce(
      (diff) =>
        dutyApi
          .generateSchedule({
            diff,
            params: duty ? {id: duty.id} : undefined,
          })
          .then((result) => onScheduleGenerate(diff, result)),
      100,
    ),
    [onScheduleGenerate],
  );

  return (
    <Panel withPadding className={styles.panel}>
      <div className={styles.content}>
        <div className={styles.form}>
          <Form<DutyDiff> onSubmit={onSubmit} subscription={{invalid: true, submitting: true, values: true}}>
            {({handleSubmit, invalid, submitting, values, form}) => {
              const unassignedTeamMembers = getUnassignedTeamMembers(teamMembers, values.rotationSettings?.rotation);
              const showUnassignedTeamMembers = unassignedTeamMembers.length < teamMembers.length;
              const scheduleGenerationData = getDataForGeneratingSchedule(values);

              return (
                <form id={formId} onSubmit={handleSubmit}>
                  <PageLock pageLocker={pageLocker} when={Boolean(scheduleGenerationData || values.description)} />
                  <Grid>
                    <Grid.Item xl={12}>
                      <FieldInput
                        name='name'
                        label={intl.formatMessage(labels.name)}
                        initialValue={duty?.name}
                        required
                      />
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FieldTextarea
                        name='description'
                        label={intl.formatMessage(labels.description)}
                        hint={intl.formatMessage(messages.descriptionHint, {
                          markdownCheatsheetLink: <MarkdownCheatsheetLink />,
                        })}
                        initialValue={duty?.description}
                        required
                      />
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FieldSlackUserGroups
                        name='slackUserGroupIds'
                        label={intl.formatMessage(labels.slackUserGroups)}
                        initialValue={duty?.slackUserGroups}
                        required
                      />
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FormControl disabled={submitting} label={intl.formatMessage(labels.owner)} required>
                        {(formControlProps) => (
                          <FieldUser
                            disabled={submitting}
                            name='ownerId'
                            validate={validateFilled}
                            initialValue={duty?.owner.id ?? selfUserId}
                            users={teamMembers}
                            {...formControlProps}
                          />
                        )}
                      </FormControl>
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <div className={styles.subtitle}>{intl.formatMessage(messages.handoffMoment)}</div>
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <Grid>
                        <Grid.Item xl={4}>
                          <FieldWeekday
                            name='rotationSettings.handoffMoment.weekday'
                            label={intl.formatMessage(labels.weekday)}
                            initialValue={duty?.rotationSettings.handoffMoment.weekday}
                            required
                          />
                        </Grid.Item>
                        <Grid.Item xl={8}>
                          <Grid>
                            <Grid.Item xl={4}>
                              <FieldTime
                                name='rotationSettings.handoffMoment.time'
                                label={intl.formatMessage(labels.time)}
                                initialValue={duty?.rotationSettings.handoffMoment.time}
                                required
                              />
                            </Grid.Item>
                            <Grid.Item xl={8}>
                              <div className={styles.timeZone}>
                                <div className={styles.timeZoneField}>
                                  <FieldTimeZone
                                    name='rotationSettings.handoffMoment.timeZone'
                                    label={intl.formatMessage(labels.timeZone)}
                                    required
                                  />
                                </div>
                                {timeZoneOffset !== 0 &&
                                  values.rotationSettings?.handoffMoment?.time &&
                                  values.rotationSettings?.handoffMoment?.weekday !== undefined && (
                                    <div className={styles.localTime}>
                                      <LocalHandoffTime
                                        offset={timeZoneOffset}
                                        time={values.rotationSettings?.handoffMoment.time}
                                        weekday={values.rotationSettings?.handoffMoment.weekday}
                                      />
                                    </div>
                                  )}
                              </div>
                            </Grid.Item>
                          </Grid>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                    <Grid.Item xl={4}>
                      {showEffectiveDate && (
                        <FieldDate
                          name='rotationSettings.effectiveDate'
                          label={intl.formatMessage(duty ? labels.effectiveDate : labels.startDate)}
                          minDate={today}
                          filterDate={filterDate(values.rotationSettings?.handoffMoment?.weekday)}
                          required
                        />
                      )}
                      <OnChange name='rotationSettings.handoffMoment.weekday'>
                        {() => {
                          if (values.rotationSettings) {
                            form.change('rotationSettings', {...values?.rotationSettings, effectiveDate: undefined});
                          }
                        }}
                      </OnChange>
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FieldRotation
                        name='rotationSettings.rotation'
                        label={intl.formatMessage(labels.rotation)}
                        required
                        users={getRotationMembers(teamMembers, unassignedTeamMembers)}
                        initialValue={duty?.rotationSettings.rotation}
                      />
                      <OnChange name='rotationSettings'>
                        {(value, previous) => {
                          if (!isEqual(value, previous)) {
                            setShowEffectiveDate(true);
                          }

                          if (scheduleGenerationData) {
                            generateSchedule.perform(scheduleGenerationData);
                          }
                        }}
                      </OnChange>
                    </Grid.Item>
                  </Grid>
                  <SubmitFormPanel>
                    <div className={styles.submitPanel}>
                      <ButtonGroup spaced size='l'>
                        <Button intent='neutral' kind='secondary' onClick={history.goBack} disabled={submitting}>
                          {intl.formatMessage(messages.buttonBack)}
                        </Button>
                        <Button
                          form={formId}
                          kind='primary'
                          intent='primary'
                          type='submit'
                          disabled={invalid}
                          loading={submitting}
                        >
                          {intl.formatMessage(messages.buttonSubmit)}
                        </Button>
                      </ButtonGroup>
                      {showUnassignedTeamMembers && <UnassignedTeamMembers users={unassignedTeamMembers} />}
                    </div>
                  </SubmitFormPanel>
                </form>
              );
            }}
          </Form>
        </div>
        {pendingChangeDate && (
          <div className={styles.callout}>
            <DutyPendingChangeCallout date={pendingChangeDate} />
            <DutyPendingChangeDialog
              date={pendingChangeDate}
              isOpen={showPendingChangeDialog}
              onClose={onPendingChangeDialogClose}
              pageLocker={pageLocker}
            />
          </div>
        )}
      </div>
      {children}
    </Panel>
  );
}
