import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {ListedCycle} from 'domain/assessment/cycle/model/listed';
import {getAllCyclesFx} from 'domain/assessment/cycle/stores/main';
import {$cycles} from 'domain/assessment/cycle/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: ListedCycle[];
  dataState: DataState;
};

export function useCycles(): ReturnShape {
  const {byId, list, listState: dataState} = useStore($cycles);
  const data = useMemo(() => list.map((id) => byId[id]).filter(isNotNullish), [byId, list]);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getAllCyclesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {data, dataState};
}
