import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  range: {
    defaultMessage: '{from} — {to} UTC',
    description: 'Assessment cycle timing',
  },
  due: {
    defaultMessage: 'Due {date} UTC',
    description: 'Assessment cycle timing',
  },
});
