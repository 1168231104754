import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import faker from 'faker';
import {GridQuestion} from '.';

export function mockGridQuestion(data: Partial<GridQuestion> = {}): GridQuestion {
  return {
    rows: Array.from({length: 4}, () => ({
      id: createMockId(),
      description: faker.lorem.sentence(),
      text: faker.lorem.sentence(),
      answerColId: undefined,
    })),
    columns: Array.from({length: 5}, () => ({
      id: createMockId(),
      text: faker.lorem.sentence(),
    })),
    ...data,
  };
}
