import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {CycleStatus} from 'domain/assessment/cycle/model/status';
import React from 'react';

type Props = {
  status: CycleStatus;
};

const intentByStatus: Record<CycleStatus, BadgeIntent> = {
  [CycleStatus.DRAFT]: 'neutral',
  [CycleStatus.PLANNED]: 'primary',
  [CycleStatus.ONGOING]: 'positive',
  [CycleStatus.COMPLETED]: 'neutral',
  [CycleStatus.ARCHIVED]: 'invertedNeutral',
};

export function CycleStatusBadge({status}: Props) {
  return <Badge intent={intentByStatus[status]}>{status}</Badge>;
}
