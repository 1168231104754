import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {buildAllRequestsDownloadLinkForAdmin} from 'domain/compensations/request/api/downloadFile';
import {useCompensationRequestFilters} from 'domain/compensations/request/hooks/useCompensationRequestFilters';
import {useCompensationRequests} from 'domain/compensations/request/hooks/useCompensationRequests';
import {CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {CompensationRequestFiltersPanel} from 'domain/compensations/request/widgets/FiltersPanel';
import {CompensationRequestsTable} from 'domain/compensations/request/widgets/Table';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/compensations/titles';
import {compensationsUrls} from 'routes/compensations/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import {ModeTabs} from './ModeTabs';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function CompensationsRequestsPage({selfUser}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const [mode] = useQueryParam('mode', oneOfEnum(CompensationRequestsMode), CompensationRequestsMode.ME);
  const hasSubordinates = Boolean(selfUser.subordinates && selfUser.subordinates.length > 0);
  const canViewAllRequests = acl.hasPermission(Permission.COMPENSATION_READ_ALL);

  const {requestsByMode, dataState, users} = useCompensationRequests();
  const {availableFilters, filterValues, filteredRequests, onFiltersChange} = useCompensationRequestFilters({
    mode,
    requestsByMode,
    users,
  });

  return (
    <Page
      title={intl.formatMessage(pageTitles.requests)}
      actions={
        <ButtonGroup spaced size='m'>
          {acl.hasPermission(Permission.COMPENSATION_DOWNLOAD_ALL) && (
            <DownloadLinkButton
              kind='text'
              intent='neutral'
              href={buildAllRequestsDownloadLinkForAdmin()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.downloadButton)}
            </DownloadLinkButton>
          )}
          {acl.hasPermission(Permission.COMPENSATION_WRITE) && (
            <RouterLinkButton
              to={compensationsUrls.createRequest()}
              size='m'
              kind='primary'
              intent='primary'
              type='submit'
            >
              {intl.formatMessage(messages.createButton)}
            </RouterLinkButton>
          )}
        </ButtonGroup>
      }
      headerContent={
        (hasSubordinates || canViewAllRequests) && (
          <ModeTabs
            activeMode={mode}
            canViewAllRequests={canViewAllRequests}
            hasSubordinates={hasSubordinates}
            requestsByMode={requestsByMode}
            selfUserId={selfUser.id}
          />
        )
      }
    >
      {[CompensationRequestsMode.ALL, CompensationRequestsMode.SUBORDINATES].includes(mode) && (
        <CompensationRequestFiltersPanel
          availableFilters={availableFilters}
          dataState={dataState}
          values={filterValues}
          onChange={onFiltersChange}
        />
      )}
      <Panel withMarginBottom={false}>
        <CompensationRequestsTable
          compensationRequests={filteredRequests}
          dataState={dataState}
          mode={mode}
          selfUserId={selfUser.id}
        />
      </Panel>
    </Page>
  );
}
