import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';

const columnIdSchema = idSchema;
export const gridQuestionSchema = object({
  columns: array(
    object({
      id: columnIdSchema,
      text: string(),
    }),
  ),
  rows: array(
    object({
      id: idSchema,
      description: optional(string()),
      text: string(),
      answerColId: optional(columnIdSchema),
    }),
  ),
});

export type GridQuestion = ExtractSchemaType<typeof gridQuestionSchema>;
