import {Page} from 'domain/assessment/questionnaire/model/entity/page';
import {QuestionnaireContentEntity} from 'domain/assessment/questionnaire/widgets/ContentEntity';
import React from 'react';

type Props = {
  page: Page;
};

export function QuestionnairePage({page}: Props) {
  // eslint-disable-next-line react/no-array-index-key
  return page.entities.map((entity, index) => <QuestionnaireContentEntity key={index} entity={entity} />);
}
