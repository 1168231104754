import isSameDay from 'date-fns/isSameDay';
import {DateTimeFormatOptions} from 'intl';
import {IntlShape} from 'react-intl';

export function formatDateRange(startDate: Date, endDate: Date, intl: IntlShape, short = false): string {
  const shortFormatOptions: DateTimeFormatOptions = {month: 'numeric', day: 'numeric'};
  const longFormatOptions: DateTimeFormatOptions = {month: 'long', day: 'numeric'};
  const options = short ? shortFormatOptions : longFormatOptions;

  if (isSameDay(startDate, endDate)) {
    return startDate.toLocaleDateString(intl.locale, options);
  }

  const formatter = new Intl.DateTimeFormat(intl.locale, options);
  return 'formatRange' in formatter
    ? formatter.formatRange(startDate, endDate)
    : `${startDate.toLocaleDateString(intl.locale, options)} – ${endDate.toLocaleDateString(intl.locale, options)}`;
}
