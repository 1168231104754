import {Panel} from '@joomcode/joom-ui/Panel';
import {Title} from 'domain/assessment/questionnaire/model/entity/title';
import React from 'react';
import styles from './styles.css';

type Props = {
  data: Title;
};

export function QuestionnaireTitleEntity({data}: Props) {
  return (
    <Panel withPadding>
      <div className={styles.title}>{data.text}</div>
    </Panel>
  );
}
