import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {mockCycleLite} from 'domain/assessment/cycle/model/lite/mock';
import {mockParticipantRole} from 'domain/assessment/cycle/model/participantRole/mock';
import {mockPageEntity} from 'domain/assessment/questionnaire/model/entity/page/mock';
import {mockQuestionnaireLite} from 'domain/assessment/questionnaire/model/lite/mock';
import {UserQuestionnaireStatus} from 'domain/assessment/questionnaire/model/status';
import {mockUser} from 'domain/user/model/mock';
import faker from 'faker';
import {UserQuestionnaire} from '.';

export function mockUserQuestionnaire(data: Partial<UserQuestionnaire> = {}): UserQuestionnaire {
  return {
    accessEndAt: faker.date.future().toISOString(),
    accessStartAt: faker.date.recent().toISOString(),
    cycle: mockCycleLite(),
    entities: Array.from({length: faker.random.number({min: 2, max: 8})}, () => mockPageEntity()),
    responder: mockUser(),
    responderRole: mockParticipantRole(),
    reviewee: mockUser(),
    status: faker.random.arrayElement(getEnumValues(UserQuestionnaireStatus)),
    questionnaire: mockQuestionnaireLite(),
    ...data,
  };
}
