import cn from 'classnames';
import React, {PropsWithChildren} from 'react';
import styles from './styles.css';

type Props = PropsWithChildren<{
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}>;

export function DashedButton({children, className, onClick}: Props) {
  return (
    <button type='button' className={cn(styles.root, className)} onClick={onClick}>
      {children}
    </button>
  );
}
