import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  button: {
    defaultMessage: 'Delete duty',
    description: '[button] Duty deletion button',
  },
  confirmationTitle: {
    defaultMessage: 'Delete duty',
    description: '[title] Duty deletion confirmation dialog: dialog title',
  },
  confirmationText: {
    defaultMessage: 'Are you sure you want to delete this duty?',
    description: 'Duty deletion confirmation dialog: dialog text',
  },
  confirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] Duty deletion confirmation dialog: confirmation button',
  },
  success: {
    defaultMessage: '{dutyName} deleted',
    description: 'Duty successful deletion text',
  },
});
