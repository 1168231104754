import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useCycle} from 'domain/assessment/cycle/hooks/useCycle';
import {cycleIdSchema} from 'domain/assessment/cycle/model/lite';
import {CycleMetaPanel} from 'domain/assessment/cycle/widgets/MetaPanel';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/assessment/titles';
import {assessmentUrls} from 'routes/assessment/urls';
import {Feature, useFeature} from 'services/features';

export function CyclePage() {
  const intl = useIntl();
  const {id} = useTypedParams({id: cycleIdSchema});
  const managerAssessmentFeature = useFeature(Feature.MANAGER_ASSESSMENT);
  const {data, dataState, clientErrorStatus} = useCycle(id);

  if (!managerAssessmentFeature.isAvailable || !id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} entityError={clientErrorStatus} state={dataState}>
      {(cycle) => (
        <Page
          title={cycle.name}
          breadcrumbs={[{title: intl.formatMessage(pageTitles.cycles), href: assessmentUrls.cycles()}]}
        >
          <CycleMetaPanel cycle={cycle} />
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
