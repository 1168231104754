import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  personalInfoSectionTitle: {
    defaultMessage: 'Personal Information',
    description: '[title] User form section title',
  },
  aboutSectionTitle: {
    defaultMessage: 'Details',
    description: '[title] User form section title',
  },
  adminSectionTitle: {
    defaultMessage: 'Admin',
    description: '[title] User form section title',
  },
  nameHint: {
    defaultMessage: 'If your legal name has changed, please contact HR Love&Care',
    description: 'First and last name form fields hint',
  },
  birthdayHint: {
    defaultMessage:
      'The day and month (without the year) are visible to everyone unless you want it to be seen only by HR',
    description: 'Date of birth form field hint',
  },
});

export const fieldLabels = defineMessages({
  allocationRule: {
    defaultMessage: 'Allocation rule',
    description: 'User form field label',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'User form field label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'User form field label',
  },
  phone: {
    defaultMessage: 'Phone number',
    description: 'User form field label',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    description: 'User form field label',
  },
  isBirthdayHrOnly: {
    defaultMessage: 'Seen only by HR Love&Care',
    description: 'User form field label',
  },
  shirtSize: {
    defaultMessage: 'Shirt size',
    description: 'User form field label',
  },
  isShirtSizeHrOnly: {
    defaultMessage: 'Seen only by HR Love&Care',
    description: 'User form field label',
  },
  slackDisplayName: {
    defaultMessage: 'Slack',
    description: 'User form field label',
  },
  email: {
    defaultMessage: 'Email',
    description: 'User form field label',
  },
  about: {
    defaultMessage: 'About me',
    description: 'User form field label',
  },
  primaryLanguage: {
    defaultMessage: 'Primary language',
    description: 'User form field label',
  },
  otherLanguages: {
    defaultMessage: 'Other languages',
    description: 'User form field label',
  },
  isHidden: {
    defaultMessage: 'Hide in company structure',
    description: 'User form field label',
  },
});
