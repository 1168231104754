import {HandoffMoment} from 'domain/duty/model/handoffMoment';
import {getLocalHandoffTime} from 'domain/duty/utils/getLocalHandoffTime';
import {getTimeZoneByOffset} from 'domain/duty/utils/timeZone';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getWeekdayName} from 'utils/date/getWeekdayName';
import {messages} from './messages';

type Props = {
  handoffMoment: HandoffMoment;
};

export function DutyHandoffMoment({handoffMoment: {weekday, time, timeZone}}: Props) {
  const intl = useIntl();
  const timeZoneOffset = useMemo(() => new Date().getTimezoneOffset(), []);
  const timeWithoutSeconds = useMemo(() => time.slice(0, 5), [time]);

  return (
    <div>
      {getWeekdayName(weekday, intl)}, {timeWithoutSeconds} {getTimeZoneByOffset(timeZone)}
      {timeZoneOffset !== 0 &&
        intl.formatMessage(messages.localHandoffTime, {
          time: getLocalHandoffTime({offset: timeZoneOffset, time, weekday, intl}),
        })}
    </div>
  );
}
