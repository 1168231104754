import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {usePageLocker} from '@joomcode/joom-ui/PageLock';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Breadcrumb, Page} from 'components/widgets/Page';
import {dutyIdSchema} from 'domain/duty/model/lite';
import {checkIsDutyManager} from 'domain/duty/utils/checkIsDutyManager';
import {DutyDeleteButton} from 'domain/duty/widgets/DeleteButton';
import {DutyUpdateForm} from 'domain/duty/widgets/UpdateForm';
import {useTeamBreadcrumbs} from 'domain/team/hooks/useTeamBreadcrumbs';
import {useTeams} from 'domain/team/hooks/useTeams';
import {teamIdSchema} from 'domain/team/model/id';
import {UserFull} from 'domain/user/model';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/teams/titles';
import {teamsUrls} from 'routes/teams/urls';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function DutyUpdatePage({selfUser}: Props) {
  const intl = useIntl();
  const {id, dutyId} = useTypedParams({id: teamIdSchema, dutyId: dutyIdSchema});
  const {byId: teamById, dataState} = useTeams();
  const teamBreadcrumbs: Breadcrumb[] = useTeamBreadcrumbs({id, teamById, includeCurrentTeam: true});
  const dutyFeature = useFeature(Feature.DUTY);
  const pageLocker = usePageLocker();

  if (!dutyFeature.isAvailable || !id || (dataState === DataState.LOADED && !teamById[id])) {
    return <NotFoundPage />;
  }

  return (
    <PageStateHandler data={teamById} state={dataState}>
      {() => {
        const team = teamById[id];
        const duty = team?.duties?.find((duty) => duty.id === dutyId);
        const teamMembers = team?.members.activeMembers.map((member) => member.user) ?? [];

        if (!team || !duty) {
          return <NotFoundPage />;
        }

        const isDutyManager = checkIsDutyManager({duty, team, selfUser});

        return (
          <Page
            title={intl.formatMessage(pageTitles.dutyUpdate)}
            breadcrumbs={[
              ...teamBreadcrumbs,
              {title: intl.formatMessage(messages.breadcrumb), href: teamsUrls.duties({id})},
            ]}
            actions={isDutyManager && <DutyDeleteButton duty={duty} pageLocker={pageLocker} teamId={team.id} />}
          >
            <DutyUpdateForm duty={duty} pageLocker={pageLocker} teamId={team.id} teamMembers={teamMembers} />
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
