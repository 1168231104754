import {TeamRoleBindingMode} from 'domain/team/model/roleBindingMode';
import {UserId} from 'domain/user/model';
import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const teamsUrlPatterns = {
  duties: '/teams/:id/duties',
  dutyCreate: '/teams/:id/duties/create',
  dutyUpdate: '/teams/:id/duties/:dutyId/edit',
  dutyOverrideCreate: '/teams/:id/duties/:dutyId/overrides/create',
  roleBindings: '/teams/:id/accesses',
  team: '/teams/:id',
  teams: '/teams',
};

export const teamsUrls = {
  duties: (pathParams: {id: string}) => generatePath(teamsUrlPatterns.duties, pathParams),
  dutyCreate: (pathParams: {id: string}) => generatePath(teamsUrlPatterns.dutyCreate, pathParams),
  dutyUpdate: (pathParams: {id: string; dutyId: string}) => generatePath(teamsUrlPatterns.dutyUpdate, pathParams),
  dutyOverrideCreate: (
    pathParams: {id: string; dutyId: string},
    searchParams?: {userIds?: UserId[]; startDate?: string; endDate?: string},
  ) => addSearchParams(generatePath(teamsUrlPatterns.dutyOverrideCreate, pathParams), searchParams),
  roleBindings: (pathParams: {id: string}, searchParams?: {mode?: TeamRoleBindingMode}) =>
    addSearchParams(generatePath(teamsUrlPatterns.roleBindings, pathParams), searchParams),
  team: (pathParams: {id: string}) => generatePath(teamsUrlPatterns.team, pathParams),
  teams: () => teamsUrlPatterns.teams,
};
