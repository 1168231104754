import {boolean, ExtractSchemaType, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';

export enum ParticipantRoleId {
  MANAGER = 'manager',
  SUBORDINATE = 'subordinate',
  UPPER_MANAGER = 'upperManager',
}

export const participantRoleSchema = object({
  isReviewee: boolean(),
  id: oneOfEnum(ParticipantRoleId),
  name: string(),
});

export type ParticipantRole = ExtractSchemaType<typeof participantRoleSchema>;
