import {DataState, getConsolidatedDataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {CompensationRequest} from 'domain/compensations/request/model';
import {CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {getAllRequestsFx} from 'domain/compensations/request/stores/main';
import {$compensationRequests} from 'domain/compensations/request/stores/main/state';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User} from 'domain/user/model';
import {checkIsSubordinate} from 'domain/user/utils/checkIsSubordinate';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  dataState: DataState;
  requestsByMode: Record<CompensationRequestsMode, CompensationRequest[]>;
  users: User[];
};

export function useCompensationRequests(): ReturnShape {
  const selfUser = useSelfUser();
  const {userById, dataState: usersDataState} = useAllUsers();
  const users = useMemo(() => Object.values(userById), [userById]);
  const {requestIds, requestsById, requestsState} = useStore($compensationRequests);

  useEffect(() => {
    if (isLoadingOrLoaded(requestsState)) {
      return;
    }
    getAllRequestsFx().catch(toaster.interceptThenThrowError);
  }, []);

  const requests = useMemo(() => requestIds.map((id) => requestsById[id]) ?? [], [requestIds, requestsById]);
  const myRequests = useMemo(
    () => requests.filter((request) => request.owner.id === selfUser?.id),
    [requests, selfUser?.id],
  );
  const subordinateRequests = useMemo(
    () => requests.filter((request) => checkIsSubordinate(selfUser, request.owner.id, users)),
    [selfUser, users, requests],
  );
  const allRequests = requests;
  const requestsByMode = useMemo(
    () => ({
      [CompensationRequestsMode.ME]: myRequests,
      [CompensationRequestsMode.SUBORDINATES]: subordinateRequests,
      [CompensationRequestsMode.ALL]: allRequests,
    }),
    [myRequests, subordinateRequests, allRequests],
  );

  return {
    dataState: getConsolidatedDataState(requestsState, usersDataState),
    requestsByMode,
    users,
  };
}
