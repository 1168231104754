import React from 'react';
import {createLocator} from '../create-locator';
import {ExternalLink} from '../ExternalLink';
import {ButtonContent} from './Content';
import {ButtonProps, DownloadLinkButtonProps, ExternalLinkButtonProps, LinkButtonProps} from './types';
import {getAnchorButtonProps, getNativeButtonProps, omitBaseButtonProps} from './props';
import {useLinkUi} from '../Link';
import {useButtonExtendedProps} from './hooks/useExtendedProps';

export * from './types';
export * from './hooks';

export const Button = React.forwardRef((rawProps: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const locator = createLocator(rawProps);
  const props = useButtonExtendedProps(rawProps);
  const restProps = omitBaseButtonProps(props);

  return (
    <button type='button' {...restProps} {...getNativeButtonProps(props)} ref={ref} {...locator()}>
      <ButtonContent {...props} />
    </button>
  );
});

export const ExternalLinkButton = React.forwardRef(function ExternalLinkButton(
  rawProps: ExternalLinkButtonProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const props = useButtonExtendedProps(rawProps);
  const restProps = omitBaseButtonProps(props);

  return (
    <ExternalLink {...restProps} {...getAnchorButtonProps(props)} ref={ref}>
      <ButtonContent {...props} />
    </ExternalLink>
  );
});

export const DownloadLinkButton = React.forwardRef(function DownloadLinkButton(
  rawProps: DownloadLinkButtonProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const props = useButtonExtendedProps(rawProps);
  const restProps = omitBaseButtonProps(props);
  const linkUi = useLinkUi({download: restProps.download, kind: 'none', ...restProps});

  return (
    <a {...linkUi} {...getAnchorButtonProps(props)} ref={ref}>
      <ButtonContent {...props} />
    </a>
  );
});

export const LinkButton = React.forwardRef(function LinkButton(
  rawProps: LinkButtonProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const props = useButtonExtendedProps(rawProps);
  const restProps = omitBaseButtonProps(props);

  return (
    <a {...restProps} {...getAnchorButtonProps(props)} ref={ref}>
      <ButtonContent {...props} />
    </a>
  );
});
