import {intranetApi} from 'apiClient';
import {mockOnError} from 'apiClient/mockOnError';
import {ListedCycle, listedCycleSchema} from 'domain/assessment/cycle/model/listed';
import {mockListedCycle} from 'domain/assessment/cycle/model/listed/mock';
import {CycleId} from 'domain/assessment/cycle/model/lite';

export const get = async (id: CycleId): Promise<ListedCycle> => {
  const {data} = await intranetApi
    .get('/v1/assessment/admin/cycles/get', {params: {id}})
    .catch(mockOnError(mockListedCycle));

  return listedCycleSchema.runWithException(data);
};
