import {usePageLocker} from '@joomcode/joom-ui/PageLock';
import {DutyDiff} from 'domain/duty/model/diff';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {Schedule} from 'domain/duty/model/schedule';
import {createDutyFx} from 'domain/duty/stores/main';
import {DutyForm} from 'domain/duty/widgets/Form';
import {DutyTimeline} from 'domain/duty/widgets/Timeline';
import {TeamId} from 'domain/team/model/id';
import {User} from 'domain/user/model';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {teamsUrls} from 'routes/teams/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  teamId: TeamId;
  teamMembers: User[];
};

export function DutyCreateForm({teamId, teamMembers}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const pageLocker = usePageLocker();
  const [schedule, setSchedule] = useState<Schedule | undefined>(undefined);

  const onScheduleGenerate = useCallback(
    (diff: RotationSettingsDiff, schedule: Schedule) => setSchedule(schedule),
    [setSchedule],
  );
  const onSubmit = useCallback(
    (diff: DutyDiff) =>
      createDutyFx({params: {teamId}, diff})
        .then((result) => {
          pageLocker.unlock();
          history.push(teamsUrls.duties({id: teamId}));
          toaster.success(intl.formatMessage(messages.success, {dutyName: result.fullName}));
        })
        .catch(toaster.interceptThenThrowError),
    [teamId, pageLocker.unlock, history.push, intl],
  );

  return (
    <DutyForm
      onScheduleGenerate={onScheduleGenerate}
      onSubmit={onSubmit}
      pageLocker={pageLocker}
      teamMembers={teamMembers}
    >
      {schedule && (
        <div className={styles.schedule}>
          <DutyTimeline
            duty={{
              title: intl.formatMessage(messages.main),
              mainSchedule: schedule.main,
              overrideSchedule: schedule.overrides,
            }}
            timezoneOffset={0}
            teamId={teamId}
          />
        </div>
      )}
    </DutyForm>
  );
}
