import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  menuAriaLabel: {
    defaultMessage: 'Assessment cycle actions menu',
    description: 'Assessment cycle actions menu: icon label',
  },
  download: {
    defaultMessage: 'Download results',
    description: 'Assessment cycle actions menu: item',
  },
  complete: {
    defaultMessage: 'Complete',
    description: 'Assessment cycle actions menu: item',
  },
  archive: {
    defaultMessage: 'Archive',
    description: 'Assessment cycle actions menu: item',
  },
  export: {
    defaultMessage: 'Export cycle setup',
    description: 'Assessment cycle actions menu: item',
  },
});
