import {Duty} from 'domain/duty/model';
import {DutyCalendarLink} from 'domain/duty/widgets/CalendarLink';
import {DutyHandoffMoment} from 'domain/duty/widgets/HandoffMoment';
import {ReactComponent as HandoffMomentIcon} from 'domain/duty/widgets/HandoffMoment/icons/handoffMoment.svg';
import {DutyOwnerIcon} from 'domain/duty/widgets/OwnerIcon';
import React from 'react';
import styles from './styles.css';

type Props = {
  duty: Duty;
};

export function DutyPanelHeader({duty}: Props) {
  return (
    <div className={styles.root}>
      {duty.name}
      <div className={styles.info}>
        <DutyOwnerIcon owner={duty.owner} />
        <HandoffMomentIcon />
        <DutyHandoffMoment handoffMoment={duty.rotationSettings.handoffMoment} />
        <DutyCalendarLink link={duty.calendarLink} />
      </div>
    </div>
  );
}
