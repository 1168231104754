import {CreateDutyConfig} from 'domain/duty/api/create';
import {CreateOverrideConfig} from 'domain/duty/api/createOverride';
import {DeactiveDutyConfig} from 'domain/duty/api/deactivate';
import {DeleteOverrideConfig} from 'domain/duty/api/deleteOverride';
import {UpdateDutyConfig} from 'domain/duty/api/update';
import {Duty} from 'domain/duty/model';
import {createEffect} from 'effector';

export const createDutyFx = createEffect<CreateDutyConfig, Duty>('create a duty');
export const createDutyOverrideFx = createEffect<CreateOverrideConfig, Duty>('create a duty override');
export const deactivateDutyFx = createEffect<DeactiveDutyConfig, void>('deactivate the duty');
export const deleteDutyOverrideFx = createEffect<DeleteOverrideConfig, Duty>('delete the duty override');
export const updateDutyFx = createEffect<UpdateDutyConfig, Duty>('update the duty');
