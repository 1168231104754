import {DataState, getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  createAutocompleteMultiselectFilter,
  createMultiSelectFilter,
  DataFilter,
  DataFilterConfig,
} from '@joomcode/joom-ui/DataFilter';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {createSimpleBooleanFilter} from 'components/ui/SimpleBooleanFilter';
import {DataFilterLoadingError} from 'components/widgets/DataFilterLoadingError';
import {
  CompensationRequestsFilterValue,
  CompensationRequestsFilterValues,
} from 'domain/compensations/request/model/filters';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {statusMessages} from 'domain/compensations/request/model/status/messages';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  availableFilters: CompensationRequestsFilterValue[];
  dataState: DataState;
  values: CompensationRequestsFilterValues;
  onChange(payload: CompensationRequestsFilterValues): void;
};

export const CompensationRequestFiltersPanel = ({
  availableFilters,
  dataState: externalDataState,
  onChange,
  values,
}: Props) => {
  const intl = useIntl();
  const {dataState: legalEntitiesDataState, legalEntities, canReadLegalEntities} = useLegalEntities();
  const dataState = getConsolidatedAccessDependentDataState(
    [legalEntitiesDataState, canReadLegalEntities],
    [externalDataState, true],
  );

  const filters = useMemo<DataFilterConfig<CompensationRequestsFilterValues>>(() => {
    return {
      ...(availableFilters.includes('statuses') && {
        statuses: createMultiSelectFilter(intl.formatMessage(messages.status), {
          alwaysVisible: true,
          options: getEnumValues(CompensationRequestStatus)
            .filter((status) => status !== CompensationRequestStatus.OFFICE_CARE_APPROVED)
            .map((status) => ({
              value: status,
              label: intl.formatMessage(statusMessages[status]),
            })),
        }),
      }),
      ...(availableFilters.includes('legalEntityIds') && {
        legalEntityIds: createAutocompleteMultiselectFilter(intl.formatMessage(messages.legalEntityId), {
          alwaysVisible: true,
          options: legalEntities.map(({id, title}) => ({
            label: title,
            value: id,
          })),
          renderOption: ({label: title}) => title,
        }),
      }),
      ...(availableFilters.includes('showIndirect') && {
        showIndirect: createSimpleBooleanFilter(intl.formatMessage(messages.showIndirect), {
          alwaysVisible: true,
        }),
      }),
    };
  }, [intl, legalEntities, availableFilters]);

  return (
    <Panel>
      <StateHandler
        data={filters}
        state={dataState}
        renderPlaceholder={() => <DataFilterSpinner />}
        renderError={() => <DataFilterLoadingError />}
      >
        {(config) => <DataFilter config={config} onChange={onChange} values={values} />}
      </StateHandler>
    </Panel>
  );
};
