import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  success: {
    defaultMessage: '{dutyName} created',
    description: 'Duty form success message',
  },
  main: {
    defaultMessage: 'Main',
    description: 'Duty form timeline group',
  },
});
