import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  cycles: {
    defaultMessage: 'Review (Admin) Cycle',
    description: '[title] Page title',
  },
  cycleImport: {
    defaultMessage: 'Create cycle',
    description: '[title] Page title',
  },
});
