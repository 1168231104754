import {closestCenter, DndContext, DragEndEvent, UniqueIdentifier} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {
  bindValidatorOptions,
  CommonFieldProps,
  getFieldValidator,
  useFieldArrayWithInitialValue,
  validateFilled,
} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plusSmall.svg';
import {Slot} from 'domain/duty/model/slot';
import {SlotDiff} from 'domain/duty/model/slot/diff';
import {User} from 'domain/user/model';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {getArrayFieldValidator} from 'utils/form/arrayFieldValidation';
import uuid from 'uuid/v4';
import {messages} from './messages';
import {SortableItem} from './SortableItem';
import styles from './styles.css';

type Props = Omit<CommonFieldProps<SlotDiff[]>, 'initialValue'> & {
  initialValue?: Slot[];
  users: User[];
};

export function FieldRotation({
  name,
  label,
  hint,
  disabled,
  required,
  initialValue,
  error,
  // extraErrorMessages,
  validate,
  users,
}: Props) {
  const intl = useIntl();
  //const {getFieldState, getRegisteredFields} = useForm();
  const validateSingleField = required ? bindValidatorOptions(validateFilled, {checkEmptyArrays: true}) : undefined;

  const initialSlotDiffs = useMemo<SlotDiff[]>(() => {
    return (
      initialValue?.map((slot) => ({
        userIds: slot.users.map((user) => user.id),
      })) ?? [{userIds: []}, {userIds: []}, {userIds: []}]
    );
  }, []);
  const [items, setItems] = useState<UniqueIdentifier[]>(() => initialSlotDiffs.map(() => uuid()));

  const fields = useFieldArrayWithInitialValue<SlotDiff>(name, {
    initialValue: initialSlotDiffs,
    validate: validate ? getArrayFieldValidator(getFieldValidator(validate)) : undefined,
  });

  const addField = useCallback(() => {
    setItems((items) => [...items, uuid()]);
    fields.push({userIds: []});
  }, [setItems, fields.push]);

  const removeField = useCallback(
    (index: number) => {
      setItems((items) => items.filter((_, i) => i !== index));
      fields.remove(index);
    },
    [setItems, fields.remove],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event;

      if (over && active.id !== over.id) {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        setItems((items) => arrayMove(items, oldIndex, newIndex));
        fields.setValue(arrayMove(fields.value, oldIndex, newIndex));
      }
    },
    [items, setItems, fields.setValue],
  );

  // const validationError = getArrayFieldError(fields.meta.error);
  // const fieldMetas = getRegisteredFields()
  //   .filter((fieldName) => fieldName.startsWith(`${name}[`))
  //   .map((fieldName) => getFieldState(fieldName))
  //   .filter(isNotNullish);
  // const isAnyTouchedFieldIsInvalid = fieldMetas.some(({touched, invalid}) => touched && invalid);
  // const validationErrorText =
  //   validationError &&
  //   isAnyTouchedFieldIsInvalid &&
  //   formatError(validationError.userIds ?? validationError, intl, {...errorMessages, ...extraErrorMessages});

  return (
    <>
      <FormControl
        hint={hint}
        label={label}
        disabled={fields.meta.submitting || disabled}
        error={error}
        required={required}
        reserveSpaceForError={false}
      >
        {() => (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              {items.map((id, index) => (
                <SortableItem
                  key={id}
                  id={id}
                  initialValue={initialSlotDiffs?.[index]?.userIds}
                  name={`${name}[${index}].userIds`}
                  onRemove={fields.length > 1 ? () => removeField(index) : undefined}
                  users={users}
                  validate={validateSingleField}
                />
              ))}
            </SortableContext>
          </DndContext>
        )}
      </FormControl>
      <div className={styles.addButton}>
        <Button
          iconLeft={<PlusIcon />}
          size='m'
          kind='text'
          intent='primary'
          type='button'
          onClick={addField}
          disabled={disabled}
          noHorizontalPadding
        >
          {intl.formatMessage(messages.addButton)}
        </Button>
      </div>
    </>
  );
}
