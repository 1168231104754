import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonNext: {
    defaultMessage: 'Next',
    description: '[button] Assessment questionnaire bottom panel button',
  },
  buttonBack: {
    defaultMessage: 'Back',
    description: '[button] Assessment questionnaire bottom panel button',
  },
  buttonSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Assessment questionnaire bottom panel button',
  },
});
