import {array, constant, ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {contentEntitySchema} from 'domain/assessment/questionnaire/model/entity/content';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';

export const pageSchema = object({
  entities: array(contentEntitySchema),
  name: string(),
});

export const pageEntitySchema = object({
  type: constant(EntityType.PAGE),
  data: pageSchema,
});

export type Page = ExtractSchemaType<typeof pageSchema>;
export type PageEntity = ExtractSchemaType<typeof pageEntitySchema>;
