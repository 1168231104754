import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  allAssigned: {
    defaultMessage: 'All team members were added to the duty rotation.',
    description: 'Duty creation form: all team members',
  },
  unassigned: {
    defaultMessage: 'Team members who were not added to the duty rotation: {users}.',
    description: 'Duty creation form: unassigned team members',
  },
});
