import {constant, ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';

export const titleSchema = object({
  text: string(),
});

export const titleEntitySchema = object({
  type: constant(EntityType.TITLE),
  data: titleSchema,
});

export type Title = ExtractSchemaType<typeof titleSchema>;
export type TitleEntity = ExtractSchemaType<typeof titleEntitySchema>;
