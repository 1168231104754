import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserId} from 'domain/user/model';
import {UserFormMode} from 'domain/user/model/formMode';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';

type ReturnShape = Record<UserFormMode, boolean>;

type Options = {
  userId?: UserId;
};

export function useFormModeAccess({userId}: Options): ReturnShape {
  const acl = useAcl();

  const userPrivateInfo = useUserPrivateInfo(userId, {enabled: false});
  const canUpdateUsers = acl.hasPermission(Permission.USER_WRITE);
  const selfUserId = useSelfUserId();
  const isSelfUser = selfUserId === userId;

  return useMemo(
    () => ({
      [UserFormMode.GENERAL]: isSelfUser || canUpdateUsers,
      [UserFormMode.CONTACTS]: userPrivateInfo.isVisibleToCurrentUser,
    }),
    [isSelfUser, userPrivateInfo.isVisibleToCurrentUser, canUpdateUsers],
  );
}
