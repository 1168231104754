import {TimelineUnit, type TimelineProps} from '@joomcode/joom-ui/Timeline';
import {type DutyTimelineProps, type Schema} from '../types';
import {useDutyRender} from './useDutyRender';
import {useGhostDutyRender} from './useGhostDutyRender';
import {useIssueIconRender} from './useIssueIconRender';
import {useIssueStripeRender} from './useIssueStripeRender';
import {useOverrideRender} from './useOverrideRender';

export function useGroups(
  {duty, ghostDuty, showOverrides, canCreateOverrides, canDeleteOverrides, teamId, dutyId}: DutyTimelineProps,
  unit: TimelineUnit,
): TimelineProps<Schema>['groups'] {
  const dutyRender = useDutyRender({canCreateOverrides, canDeleteOverrides, dutyId, teamId});
  const ghostDutyRender = useGhostDutyRender();
  const overrideRender = useOverrideRender({canCreateOverrides, canDeleteOverrides, dutyId, teamId});
  const issueStripeRender = useIssueStripeRender(unit);
  const issueIconRender = useIssueIconRender(unit);

  const issueIconGroups = duty ? [{title: duty.title, id: 'issueIcon', render: issueIconRender} as const] : [];
  const issueStripeGroups = duty ? [{id: 'issueStripe', height: 8, render: issueStripeRender} as const] : [];
  const dutyGroups = duty ? [{id: 'duty', height: 70, render: dutyRender} as const] : [];
  const ghostDutyGroups = ghostDuty
    ? ([{title: ghostDuty.title}, {id: 'ghostDuty', height: 70, render: ghostDutyRender}] as const)
    : [];
  const overrideGroups = showOverrides
    ? ([{title: 'Overrides'}, {id: 'override', height: 70, render: overrideRender}] as const)
    : [];

  return [...issueIconGroups, ...issueStripeGroups, ...dutyGroups, ...ghostDutyGroups, ...overrideGroups];
}
