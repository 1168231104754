import faker from 'faker';
import {Description} from '.';

export function mockDescription(data: Partial<Description> = {}): Description {
  return {
    backgroundColor: faker.random.boolean() ? faker.internet.color() : undefined,
    emoji: faker.random.boolean() ? faker.random.arrayElement(['🚀', '💪', '🎉', '⭐', '🔥']) : undefined,
    text: faker.lorem.sentences(),
    ...data,
  };
}
