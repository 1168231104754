import {StyledLink} from 'components/ui/StyledLink';
import {DutyLite} from 'domain/duty/model/lite';
import React from 'react';
import {teamsUrls} from 'routes/teams/urls';

type Props = {
  duty: DutyLite;
};

export function DutyLink({duty}: Props) {
  return (
    <StyledLink to={teamsUrls.duties({id: duty.teamId})} colored target='_blank'>
      {duty.fullName}
    </StyledLink>
  );
}
