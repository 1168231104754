import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';

export const questionnaireIdSchema = idSchema;
export const questionnaireLiteSchema = object({
  description: string(),
  id: questionnaireIdSchema,
  name: string(),
});

export type QuestionnaireId = ExtractSchemaType<typeof questionnaireIdSchema>;
export type QuestionnaireLite = ExtractSchemaType<typeof questionnaireLiteSchema>;
