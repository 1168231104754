import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {bindValidatorOptions, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Grid} from '@joomcode/joom-ui/Grid';
import {PageLock, usePageLocker} from '@joomcode/joom-ui/PageLock';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {isToday} from 'date-fns';
import {dutyApi} from 'domain/duty/api';
import {CreateOverrideDiff} from 'domain/duty/api/createOverride';
import {Duty} from 'domain/duty/model';
import {ScheduleIssue} from 'domain/duty/model/scheduleIssue';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {SlotDiff} from 'domain/duty/model/slot/diff';
import {createDutyOverrideFx} from 'domain/duty/stores/main';
import {getScheduleItemOnDate} from 'domain/duty/utils/getScheduleItemOnDate';
import {getDefaultTimeZone} from 'domain/duty/utils/timeZone';
import {FastOptions} from 'domain/duty/widgets/FastOptions';
import {FieldTime} from 'domain/duty/widgets/FieldTime';
import {FieldTimeZone} from 'domain/duty/widgets/FieldTimeZone';
import {ScheduleIssueBadge} from 'domain/duty/widgets/ScheduleIssueBadge';
import {DutyTimeline} from 'domain/duty/widgets/Timeline';
import {DutyTimelineSize} from 'domain/duty/widgets/Timeline/types';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TeamId} from 'domain/team/model/id';
import {User} from 'domain/user/model';
import {FieldUsers} from 'domain/user/widgets/FieldMultiple';
import {generalMessages} from 'i18n/messages/general';
import pDebounce from 'p-debounce';
import React, {useCallback, useMemo, useState} from 'react';
import {Form, FormSpy} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {teamsUrls} from 'routes/teams/urls';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {errorMessages, labels, messages} from './messages';
import styles from './styles.css';
import {
  getDateFromUrlParams,
  getDiffFromState,
  getFormattedDate,
  getInitialDate,
  getInitialTime,
  getLocalTime,
  getNewScheduleItem,
} from './utils';
import {validateOverrideForm} from './validate';

const now = new Date();

export type FormState = {
  startDate: string;
  startTime: string;
  timeZone: string;
  slot: SlotDiff;
  endDate: string;
  endTime: string;
};

type Props = {
  duty: Duty;
  teamId: TeamId;
  teamMembers: User[];
};

type ScheduleIssueWithUser = ScheduleIssue & {user: User};

export function DutyOverrideForm({duty, teamId, teamMembers}: Props) {
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const history = useHistory();
  const {search} = history.location;
  const formId = useMemo(uuid, []);
  const pageLocker = usePageLocker();
  const timeZoneOffset = useMemo(() => now.getTimezoneOffset(), []);
  const inititalUserIds = useMemo(() => {
    const userIds = new URLSearchParams(search).getAll('userIds');
    const result = selfUserId && userIds.includes(selfUserId) ? userIds : [...userIds, selfUserId];
    const teamMemberIds = teamMembers.map((user) => user.id);

    return result.filter(isNotNullish).filter((id) => teamMemberIds.includes(id));
  }, [search, selfUserId, teamMembers]);
  const inititalStartDate = useMemo(() => getInitialDate('startDate', search), [search]);
  const initialStartTime = useMemo(() => getInitialTime('startDate', search), [search]);
  const inititalEndDate = useMemo(() => getInitialDate('endDate', search), [search]);
  const initialEndTime = useMemo(() => getInitialTime('endDate', search), [search]);
  const initialScrollDate = useMemo(() => {
    const date = getDateFromUrlParams('startDate', search);
    if (!date) {
      return undefined;
    }

    const scheduleItem = getScheduleItemOnDate(duty.schedule.main, date);
    return scheduleItem ? new Date(scheduleItem.startDate) : undefined;
  }, [search]);
  const [issues, setIssues] = useState<ScheduleIssueWithUser[]>([]);
  const [overrideItems, setOverrideItems] = useState<ScheduleItem[]>(duty.schedule.overrides);
  const validateUsers = bindValidatorOptions(validateFilled, {checkEmptyArrays: true});
  const validateForm = useCallback(
    (values: Partial<FormState>) => validateOverrideForm(values, {overrides: duty.schedule.overrides}),
    [duty.schedule.overrides],
  );
  const resetEndTime = useCallback((state: FormState, reset: (name: keyof FormState) => void) => {
    if (state.endDate && state.startDate === state.endDate && state.endTime <= state.startTime) {
      reset('endTime');
    }
  }, []);
  const resetEndDate = useCallback((state: FormState, reset: (name: keyof FormState) => void) => {
    if (state.startDate > state.endDate) {
      reset('endDate');
    }
  }, []);
  const getMinEndTime = useCallback(
    (state: FormState) => {
      if (state.endDate && state.startDate === state.endDate && state.startTime) {
        const date = new Date(state.startDate);
        const [hours, minutes] = state.startTime.split(':').map(Number);
        date.setUTCHours(hours, minutes + 1);

        return date;
      }

      if (state.endDate && isToday(new Date(state.endDate))) {
        return now;
      }

      return undefined;
    },
    [now],
  );

  const getIssues = useAsyncTask(
    pDebounce(
      (state: FormState, diff: CreateOverrideDiff) =>
        dutyApi.getIssues({diff, params: {teamId, dutyId: duty.id}}).then((result) => {
          const issues = result
            .map((issue) => {
              const user = teamMembers.find((user) => user.id === issue.userId);
              return user ? {...issue, user} : undefined;
            })
            .filter(isNotNullish);
          setIssues(issues);

          const newScheduleItem = getNewScheduleItem(state, issues, teamMembers);
          if (newScheduleItem) {
            setOverrideItems([...duty.schedule.overrides, newScheduleItem]);
          }
        }),
      50,
    ),
    [setIssues, setOverrideItems, teamMembers, duty.schedule.overrides, duty.id, teamId],
  );

  const onSubmit = useCallback(
    (state: FormState) => {
      const diff = getDiffFromState(state);
      if (!diff) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(generalMessages.partiallyFilledError)));
      }

      return createDutyOverrideFx({params: {teamId, dutyId: duty.id}, diff})
        .then(() => {
          pageLocker.unlock();
          history.push(teamsUrls.duties({id: teamId}));
          toaster.success(
            intl.formatMessage(messages.success, {
              dutyName: duty.name,
              startDate: getFormattedDate(state.startDate, state.startTime, intl),
              endDate: getFormattedDate(state.endDate, state.endTime, intl),
              timeZone: getDefaultTimeZone(),
              users: intl.formatList(
                state.slot.userIds.map((id) => teamMembers.find((user) => user.id === id)?.fullName),
                {type: 'conjunction', style: 'long'},
              ),
            }),
          );
        })
        .catch(toaster.interceptThenThrowError);
    },
    [teamId, duty.id, pageLocker.unlock, history.push, intl],
  );

  return (
    <Panel withPadding className={styles.panel}>
      <Form<FormState> onSubmit={onSubmit} validate={validateForm}>
        {({handleSubmit, invalid, submitting, values, valid, form, errors}) => {
          return (
            <form id={formId} onSubmit={handleSubmit} className={styles.form}>
              <PageLock pageLocker={pageLocker} when={valid} />
              <Grid>
                <Grid.Item xl={3}>
                  <FieldDate
                    name='startDate'
                    label={intl.formatMessage(labels.startDate)}
                    minDate={now}
                    initialValue={inititalStartDate}
                    required
                  />
                </Grid.Item>
                <Grid.Item xl={3}>
                  <FieldTime
                    name='startTime'
                    label={intl.formatMessage(labels.startTime)}
                    minDate={values.startDate && isToday(new Date(values.startDate)) ? now : undefined}
                    initialValue={initialStartTime}
                    required
                  />
                </Grid.Item>
                <Grid.Item xl={3}>
                  <FieldTimeZone name='timeZone' label={intl.formatMessage(labels.timeZone)} required />
                </Grid.Item>
                <Grid.Item xl={3}>
                  {values.startTime && timeZoneOffset !== 0 ? (
                    <>
                      <div className={styles.absoluteWrapper}>
                        <div className={styles.fastOptions}>
                          <FastOptions change={form.change} schedule={duty.schedule.main} />
                        </div>
                      </div>
                      <div className={styles.localTimeWrapper}>
                        <div className={styles.localTime}>
                          {intl.formatMessage(messages.localTime, {
                            time: getLocalTime({
                              dateString: values.startDate,
                              time: values.startTime,
                              intl,
                            }),
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.absoluteWrapper}>
                      <FastOptions change={form.change} schedule={duty.schedule.main} />
                    </div>
                  )}
                </Grid.Item>
                <Grid.Item xl={3}>
                  <FieldDate
                    name='endDate'
                    label={intl.formatMessage(labels.endDate)}
                    minDate={values.startDate ? new Date(values.startDate) : now}
                    initialValue={inititalEndDate}
                    required
                  />
                  <OnChange name='startDate'>{() => resetEndDate(values, form.change)}</OnChange>
                  {errors?.overlapping && (
                    <div className={styles.errorContainer}>
                      <div className={styles.error}>
                        <Error message={intl.formatMessage(errorMessages.overlappingOverride)} />
                      </div>
                    </div>
                  )}
                </Grid.Item>
                <Grid.Item xl={3}>
                  <FieldTime
                    name='endTime'
                    label={intl.formatMessage(labels.endTime)}
                    minDate={getMinEndTime(values)}
                    initialValue={initialEndTime}
                    required
                  />
                  <OnChange name='startDate'>{() => resetEndTime(values, form.change)}</OnChange>
                  <OnChange name='startTime'>{() => resetEndTime(values, form.change)}</OnChange>
                  <OnChange name='endDate'>{() => resetEndTime(values, form.change)}</OnChange>
                </Grid.Item>
                <Grid.Item xl={3}>
                  {values.endTime && timeZoneOffset !== 0 && (
                    <div className={styles.localTimeWrapper}>
                      <div className={styles.localTime}>
                        {intl.formatMessage(messages.localTime, {
                          time: getLocalTime({
                            dateString: values.endDate,
                            time: values.endTime,
                            intl,
                          }),
                        })}
                      </div>
                    </div>
                  )}
                </Grid.Item>
                <Grid.Item xl={3}>
                  <div />
                </Grid.Item>
                <Grid.Item xl={6}>
                  <FormControl disabled={submitting} label={intl.formatMessage(labels.overrideDuty)} required>
                    {(formControlProps) => (
                      <FieldUsers
                        name='slot.userIds'
                        users={teamMembers}
                        validate={validateUsers}
                        disabled={submitting}
                        initialValue={inititalUserIds}
                        {...formControlProps}
                      />
                    )}
                  </FormControl>
                </Grid.Item>
                <Grid.Item xl={6}>
                  <div className={styles.issues}>
                    {issues.map((issue) => (
                      <ScheduleIssueBadge key={issue.id} issue={issue} user={issue.user} withFullName />
                    ))}
                  </div>
                </Grid.Item>
              </Grid>
              <SubmitFormPanel>
                <ButtonGroup spaced size='l'>
                  <Button intent='neutral' kind='secondary' onClick={history.goBack} disabled={submitting}>
                    {intl.formatMessage(messages.buttonBack)}
                  </Button>
                  <Button
                    form={formId}
                    kind='primary'
                    intent='primary'
                    type='submit'
                    disabled={invalid}
                    loading={submitting}
                  >
                    {intl.formatMessage(messages.buttonSubmit)}
                  </Button>
                </ButtonGroup>
              </SubmitFormPanel>
              <FormSpy<FormState>
                subscription={{values: true, valid: true}}
                onChange={({values, valid}) => {
                  const diff = getDiffFromState(values);
                  if (valid && diff) {
                    getIssues.perform(values, diff);
                  }
                }}
              />
            </form>
          );
        }}
      </Form>
      <div className={styles.schedule}>
        <DutyTimeline
          controls={{defaultSize: DutyTimelineSize.WEEK}}
          duty={{
            title: intl.formatMessage(messages.main),
            mainSchedule: duty.schedule.main,
            overrideSchedule: overrideItems,
          }}
          timezoneOffset={0}
          dutyId={duty.id}
          teamId={teamId}
          showOverrides
          canDeleteOverrides
          initialScrollDate={initialScrollDate}
        />
      </div>
    </Panel>
  );
}
