import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {PageTabs} from 'components/ui/PageTabs';
import {CompensationRequest} from 'domain/compensations/request/model';
import {checkRequestNeedsReaction, CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {modeNames} from 'domain/compensations/request/model/mode/messages';
import {UserId} from 'domain/user/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {compensationsUrls} from 'routes/compensations/urls';

type Props = {
  activeMode: CompensationRequestsMode;
  canViewAllRequests: boolean;
  hasSubordinates: boolean;
  requestsByMode: Record<CompensationRequestsMode, CompensationRequest[]>;
  selfUserId: UserId;
};

export function ModeTabs({activeMode, canViewAllRequests, hasSubordinates, requestsByMode, selfUserId}: Props) {
  const intl = useIntl();
  const tabVisibilityByMode = {
    [CompensationRequestsMode.ME]: true,
    [CompensationRequestsMode.SUBORDINATES]: hasSubordinates,
    [CompensationRequestsMode.ALL]: canViewAllRequests,
  };

  const notificationsCountByMode = useMemo(
    (): Record<CompensationRequestsMode, number> =>
      arrayToObject(
        getEnumValues(CompensationRequestsMode),
        identity,
        (mode) => requestsByMode[mode].filter((request) => checkRequestNeedsReaction(request, mode, selfUserId)).length,
      ),
    [selfUserId, requestsByMode],
  );

  return (
    <PageTabs>
      {getEnumValues(CompensationRequestsMode).map((mode) =>
        tabVisibilityByMode[mode] ? (
          <PageTabs.Tab
            title={intl.formatMessage(modeNames[mode])}
            path={compensationsUrls.requests({mode})}
            active={activeMode === mode}
            notificationsCount={notificationsCountByMode[mode]}
            key={mode}
          />
        ) : null,
      )}
    </PageTabs>
  );
}
