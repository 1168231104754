import {User, UserId} from 'domain/user/model';

/**
 * Checks whether user with given ID has indirect subordinates
 */
export function checkHasIndirectSubordinates(userId: UserId | undefined, allUsers: User[]) {
  const managers = allUsers.filter(({id}) => allUsers.some(({managerId}) => id === managerId));

  return managers.some((manager) => manager.managerId === userId);
}
