import {
  boolean,
  constant,
  ExtractSchemaType,
  object,
  oneOf,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {gridQuestionSchema} from 'domain/assessment/questionnaire/model/entity/question/grid';
import {QuestionType} from 'domain/assessment/questionnaire/model/entity/question/type';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const questionSchema = object({
  answerComment: optional(string()),
  answerRequired: optionalWithDefault(false, boolean()),
  commentAllowed: optionalWithDefault(false, boolean()),
  data: oneOf(gridQuestionSchema),
  description: optional(string()),
  text: string(),
  type: oneOfEnum(QuestionType),
});

export const questionEntitySchema = object({
  type: constant(EntityType.QUESTION),
  data: questionSchema,
});

export type Question = ExtractSchemaType<typeof questionSchema>;
export type QuestionEntity = ExtractSchemaType<typeof questionEntitySchema>;
