import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Duty form field label',
  },
  description: {
    defaultMessage: 'About duty',
    description: 'Duty form field label',
  },
  slackUserGroups: {
    defaultMessage: 'Slack usergroups',
    description: 'Duty form field label',
  },
  owner: {
    defaultMessage: 'Duty owner',
    description: 'Duty form field label',
  },
  weekday: {
    defaultMessage: 'Weekday',
    description: 'Duty form field label',
  },
  time: {
    defaultMessage: 'Time',
    description: 'Duty form field label',
  },
  timeZone: {
    defaultMessage: 'Time zone',
    description: 'Duty form field label',
  },
  startDate: {
    defaultMessage: 'Start date',
    description: 'Duty form field label',
  },
  effectiveDate: {
    defaultMessage: 'Effective date',
    description: 'Duty form field label',
  },
  rotation: {
    defaultMessage: 'Duty shifts',
    description: 'Duty form field label',
  },
});

export const messages = defineMessages({
  buttonSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Submit duty form',
  },
  buttonBack: {
    defaultMessage: 'Back',
    description: '[button] Going back from duty form',
  },
  descriptionHint: {
    defaultMessage:
      'E.g., on-call duty responsibilities or main rules. The description is visible for all. {markdownCheatsheetLink}.',
    description: 'Duty form field hint',
  },
  handoffMoment: {
    defaultMessage: 'Handoff moment',
    description: '[title] Duty form subtitle',
  },
});
