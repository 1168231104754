import {FormControl} from '@joomcode/joom-ui/FormControl';
import {AsyncAutocomplete, AsyncAutocompleteProps} from '@joomcode/joom-ui/Select/AsyncAutocomplete';
import React from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {getFieldErrorText} from '../../utils';
import {validateFilled, composeValidators, getFieldValidator} from '../../validation';
import {CommonFieldProps} from '../types';

export type FieldAsyncAutocompleteProps<Id extends string, Item> = Omit<
  AsyncAutocompleteProps<Id, Item>,
  'onChange' | 'value'
> &
  CommonFieldProps<Id>;

export const FieldAsyncAutocomplete = function FieldAsyncAutocomplete<Id extends string, Item>({
  disabled,
  error,
  hint,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  validate,
  getItemId,
  ...autocompleteProps
}: FieldAsyncAutocompleteProps<Id, Item>) {
  const intl = useIntl();

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<Id>(name, {
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      labelHint={labelHint}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <AsyncAutocomplete<Id, Item>
          {...formControlProps}
          {...input}
          {...autocompleteProps}
          getItemId={getItemId}
          value={input.value}
          onChange={input.onChange}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
};
