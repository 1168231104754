import {DataState} from '@joomcode/deprecated-utils/dataState';
import {SkeletonShape} from '@joomcode/joom-ui/Skeleton';
import {DashedButton} from 'components/ui/DashedButton';
import {$stockOptionsProposal} from 'domain/offer/stores/stockOptionsProposal/state';
import {useStore} from 'effector-react';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  enteredAmount: number;
  onRecommendationClick(amount: number): void;
};

export function StockOptionsProposal({enteredAmount, onRecommendationClick}: Props) {
  const intl = useIntl();
  const {amount, dataState} = useStore($stockOptionsProposal);

  if (dataState === DataState.LOADING) {
    return <SkeletonShape kind='rectangle' width='10.625rem' height='2rem' />;
  }

  if (!amount || amount === enteredAmount) {
    return null;
  }

  return (
    <div className={styles.root}>
      {intl.formatMessage(messages.recommendation, {
        amount: (
          <DashedButton className={styles.button} onClick={() => onRecommendationClick(amount)}>
            {intl.formatNumber(amount)}
          </DashedButton>
        ),
      })}
    </div>
  );
}
