import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {composeValidators, FieldTextarea, validateFilled, validateUrl} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Select} from '@joomcode/joom-ui/Select';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {Toggle} from '@joomcode/joom-ui/Toggle';
import cn from 'classnames';
import {HintIcon} from 'components/ui/HintIcon';
import {MarkdownCheatsheetLink} from 'components/ui/MarkdownCheatsheetLink';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {FieldPhone} from 'components/widgets/fields/FieldPhone';
import addDays from 'date-fns/addDays';
import {Language} from 'domain/language/model';
import {LanguageSelect, LanguagesSelect} from 'domain/language/widgets/Select';
import {Permission} from 'domain/permission/model';
import {ShirtSize} from 'domain/shirtSize/model';
import {shirtSizeLabels} from 'domain/shirtSize/model/messages';
import {SocialMedia} from 'domain/socialMedia/model';
import {socialMediaNames} from 'domain/socialMedia/model/messages';
import {UserFull} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import React, {useCallback, useMemo} from 'react';
import {Field, useField} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {validateLatinName} from 'utils/form/latinNameValidation';
import uuid from 'uuid/v4';
import {errorMessages} from '../messages';
import styles from '../UserForm.css';
import {fieldLabels, messages} from './messages';

type Props = {
  privateInfo?: UserPrivateInfo;
  submitting?: boolean;
  user: UserFull;
};

const URL_FIELD_PLACEHOLDER = 'https://';
const shirtSizes = getEnumValues(ShirtSize);
const reverseBoolean = (value: boolean) => !value;

export function UserFormGeneral({privateInfo, submitting, user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const birthdaySwitchId = useMemo(uuid, []);
  const shirtSwitchId = useMemo(uuid, []);
  const hiddenSwitchId = useMemo(uuid, []);
  const maxBirthday = useMemo(() => addDays(new Date(), -1), []);
  const {meta: phoneFieldMeta} = useField<string>('phone');
  const canEditNameFields = acl.hasPermission(Permission.USER_WRITE);

  const getShirtSizeLabel = useCallback((size: ShirtSize) => intl.formatMessage(shirtSizeLabels[size]), [intl]);

  return (
    <>
      <Panel.Header title={intl.formatMessage(messages.personalInfoSectionTitle)} withSeparator />
      <Panel.Content withPadding>
        <div className={cn(styles.form, styles.compact)}>
          <Field<string>
            name='firstName'
            initialValue={user.firstName}
            parse={identity}
            validate={composeValidators(validateFilled, validateLatinName)}
          >
            {({input, meta}) => (
              <FormControl
                error={getFieldErrorText(meta, {intl, extraMessages: errorMessages})}
                disabled={submitting || !canEditNameFields}
                label={
                  <>
                    {intl.formatMessage(fieldLabels.firstName)}
                    {!canEditNameFields && <HintIcon>{intl.formatMessage(messages.nameHint)}</HintIcon>}
                  </>
                }
                required={canEditNameFields}
              >
                {(formControlProps) => (
                  <Input {...formControlProps} {...input} disabled={submitting || !canEditNameFields} />
                )}
              </FormControl>
            )}
          </Field>
          <Field<string>
            name='lastName'
            initialValue={user.lastName}
            parse={identity}
            validate={composeValidators(validateFilled, validateLatinName)}
          >
            {({input, meta}) => (
              <FormControl
                error={getFieldErrorText(meta, {intl, extraMessages: errorMessages})}
                disabled={submitting || !canEditNameFields}
                label={
                  <>
                    {intl.formatMessage(fieldLabels.lastName)}
                    {!canEditNameFields && <HintIcon>{intl.formatMessage(messages.nameHint)}</HintIcon>}
                  </>
                }
                required={canEditNameFields}
              >
                {(formControlProps) => (
                  <Input {...formControlProps} {...input} disabled={submitting || !canEditNameFields} />
                )}
              </FormControl>
            )}
          </Field>
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(fieldLabels.phone)}
            error={getFieldErrorText(phoneFieldMeta, {intl})}
            required
          >
            {(formControlProps) => (
              <FieldPhone
                countryCode={privateInfo?.legalEntity?.countryCode}
                disabled={submitting}
                initialValue={user.phone}
                validate={validateFilled}
                name='phone'
                {...formControlProps}
              />
            )}
          </FormControl>
          {privateInfo && (
            <>
              <div className={styles.newRow}>
                <FieldDate
                  name='dateOfBirth'
                  label={
                    <>
                      {intl.formatMessage(fieldLabels.dateOfBirth)}
                      <HintIcon>{intl.formatMessage(messages.birthdayHint)}</HintIcon>
                    </>
                  }
                  initialValue={privateInfo.dateOfBirth}
                  maxDate={maxBirthday}
                  emptyValue={privateInfo.dateOfBirth ? null : undefined}
                />
              </div>
              <div className={styles.switchField}>
                <Field<boolean>
                  name='isMonthAndDayOfBirthPublic'
                  initialValue={privateInfo.isMonthAndDayOfBirthPublic}
                  format={reverseBoolean}
                  parse={reverseBoolean}
                >
                  {({input: {value, ...input}}) => (
                    <Toggle defaultChecked={value} disabled={submitting} id={birthdaySwitchId} {...input} />
                  )}
                </Field>
                <label htmlFor={birthdaySwitchId} className={styles.switchLabel}>
                  {intl.formatMessage(fieldLabels.isBirthdayHrOnly)}
                </label>
              </div>
              <div className={styles.newRow}>
                <Field<ShirtSize> name='shirtSize' initialValue={privateInfo.shirtSize} parse={identity}>
                  {({input: {value, ...input}}) => (
                    <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.shirtSize)}>
                      {(formControlProps) => (
                        <Select<ShirtSize>
                          clearable
                          items={shirtSizes}
                          getItemKey={identity}
                          itemToString={getShirtSizeLabel}
                          renderItem={getShirtSizeLabel}
                          value={value || null}
                          disabled={submitting}
                          {...formControlProps}
                          {...input}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.switchField}>
                <Field<boolean>
                  name='isShirtSizePublic'
                  initialValue={privateInfo.isShirtSizePublic}
                  format={reverseBoolean}
                  parse={reverseBoolean}
                >
                  {({input: {value, ...input}}) => (
                    <Toggle defaultChecked={value} disabled={submitting} id={shirtSwitchId} {...input} />
                  )}
                </Field>
                <label htmlFor={shirtSwitchId} className={styles.switchLabel}>
                  {intl.formatMessage(fieldLabels.isShirtSizeHrOnly)}
                </label>
              </div>
            </>
          )}
          <div className={styles.newRow}>
            <Field<Language> name='primaryLanguage' initialValue={user.primaryLanguage}>
              {({input}) => (
                <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.primaryLanguage)}>
                  {(formControlProps) => (
                    <LanguageSelect {...formControlProps} {...input} clearable disabled={submitting} />
                  )}
                </FormControl>
              )}
            </Field>
          </div>
          <Field<Language[]> name='otherLanguages' initialValue={user.otherLanguages}>
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.otherLanguages)}>
                {(formControlProps) => <LanguagesSelect {...formControlProps} {...input} disabled={submitting} />}
              </FormControl>
            )}
          </Field>
          <FormControl disabled label={intl.formatMessage(fieldLabels.slackDisplayName)}>
            {() => <Input disabled value={user.slackDisplayName} />}
          </FormControl>
          <FormControl disabled label={intl.formatMessage(fieldLabels.email)}>
            {() => <Input disabled value={user.email} />}
          </FormControl>
        </div>
      </Panel.Content>
      <Panel.Header title={intl.formatMessage(messages.aboutSectionTitle)} withSeparator />
      <Panel.Content withPadding>
        <div className={styles.form}>
          <div className={cn(styles.newRow, styles.fullRow)}>
            <Field<string> name='about' initialValue={user.about} parse={identity}>
              {({input}) => (
                <FormControl
                  disabled={submitting}
                  label={intl.formatMessage(fieldLabels.about)}
                  hint={<MarkdownCheatsheetLink />}
                >
                  {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
                </FormControl>
              )}
            </Field>
          </div>
          <Field<string>
            name={`socialMediaLinks.${SocialMedia.FACEBOOK}`}
            initialValue={user.socialMediaLinks?.[SocialMedia.FACEBOOK]}
            parse={identity}
            validate={validateUrl}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(socialMediaNames[SocialMedia.FACEBOOK])}
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => (
                  <Input placeholder={URL_FIELD_PLACEHOLDER} {...formControlProps} {...input} disabled={submitting} />
                )}
              </FormControl>
            )}
          </Field>
          <Field<string>
            name={`socialMediaLinks.${SocialMedia.INSTAGRAM}`}
            initialValue={user.socialMediaLinks?.[SocialMedia.INSTAGRAM]}
            parse={identity}
            validate={validateUrl}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(socialMediaNames[SocialMedia.INSTAGRAM])}
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => (
                  <Input placeholder={URL_FIELD_PLACEHOLDER} {...formControlProps} {...input} disabled={submitting} />
                )}
              </FormControl>
            )}
          </Field>
          <Field<string>
            name={`socialMediaLinks.${SocialMedia.LINKED_IN}`}
            initialValue={user.socialMediaLinks?.[SocialMedia.LINKED_IN]}
            parse={identity}
            validate={validateUrl}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(socialMediaNames[SocialMedia.LINKED_IN])}
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => (
                  <Input placeholder={URL_FIELD_PLACEHOLDER} {...formControlProps} {...input} disabled={submitting} />
                )}
              </FormControl>
            )}
          </Field>
          <Field<string>
            name={`socialMediaLinks.${SocialMedia.TWITTER}`}
            initialValue={user.socialMediaLinks?.[SocialMedia.TWITTER]}
            parse={identity}
            validate={validateUrl}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(socialMediaNames[SocialMedia.TWITTER])}
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => (
                  <Input placeholder={URL_FIELD_PLACEHOLDER} {...formControlProps} {...input} disabled={submitting} />
                )}
              </FormControl>
            )}
          </Field>
        </div>
      </Panel.Content>
      {acl.hasPermission(Permission.USER_WRITE) && (
        <>
          <Panel.Header title={intl.formatMessage(messages.adminSectionTitle)} withSeparator />
          <Panel.Content withPadding>
            <div className={styles.form}>
              <div className={styles.fullRow}>
                <FieldTextarea
                  name='allocationRule'
                  label={intl.formatMessage(fieldLabels.allocationRule)}
                  initialValue={user.allocationRule}
                  parse={identity}
                  rows={2}
                />
              </div>
              <div className={styles.fullRow}>
                <div className={styles.switchField}>
                  <Field<boolean> name='hidden' initialValue={user.hidden}>
                    {({input: {value, ...input}}) => (
                      <Toggle defaultChecked={value} disabled={submitting} id={hiddenSwitchId} {...input} />
                    )}
                  </Field>
                  <label htmlFor={hiddenSwitchId} className={styles.switchLabel}>
                    {intl.formatMessage(fieldLabels.isHidden)}
                  </label>
                </div>
              </div>
            </div>
          </Panel.Content>
        </>
      )}
    </>
  );
}
