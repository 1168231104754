import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {PageLocker} from '@joomcode/joom-ui/PageLock';
import {Duty} from 'domain/duty/model';
import {deactivateDutyFx} from 'domain/duty/stores/main';
import {TeamId} from 'domain/team/model/id';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {teamsUrls} from 'routes/teams/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  duty: Duty;
  pageLocker: PageLocker;
  teamId: TeamId;
};

export function DutyDeleteButton({duty, pageLocker, teamId}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const {id} = duty;
  const handleClick = useCallback(() => {
    confirm(
      {
        title: intl.formatMessage(messages.confirmationTitle),
        text: intl.formatMessage(messages.confirmationText),
        confirmationText: intl.formatMessage(messages.confirmationButton),
        intent: 'negative',
        onConfirm: () =>
          deactivateDutyFx({id, teamId})
            .then(() => {
              pageLocker.unlock();
              toaster.success(intl.formatMessage(messages.success, {dutyName: duty.fullName}));
              history.push(teamsUrls.duties({id: teamId}));
            })
            .catch(toaster.interceptThenThrowError),
      },
      intl,
    );
  }, [intl, history, id, teamId, pageLocker.unlock]);

  return (
    <Button size='m' kind='primary' intent='negative' onClick={handleClick}>
      {intl.formatMessage(messages.button)}
    </Button>
  );
}
