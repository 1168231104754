import {SecurePermission} from 'domain/permission/model/secure';
import {useIsStockOptionHolder} from 'domain/self/hooks/useIsStockOptionHolder';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {UserId} from 'domain/user/model';
import {useAcl} from 'services/acl';

type Options = {
  userId?: UserId;
  userAncestorIds?: UserId[];
};

export const useStockOptionReadAccess = ({userId, userAncestorIds = []}: Options): StockOptionReadAccess | null => {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const isStockOptionHolder = useIsStockOptionHolder();

  if (!userId) {
    return null;
  }

  const canReadSubordinate = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE);
  const canReadAny = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);

  if (canReadAny) {
    return StockOptionReadAccess.ANY;
  }

  if (canReadSubordinate && selfUserId && userAncestorIds.includes(selfUserId)) {
    return StockOptionReadAccess.SUBORDINATE;
  }

  if (userId === selfUserId && isStockOptionHolder) {
    return StockOptionReadAccess.MY;
  }

  return null;
};
