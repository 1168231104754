import cn from 'classnames';
import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import {Input} from '../Input';
import styles from './index.css';

const LazyInputMask = React.lazy(() => import('react-input-mask' /* webpackChunkName: 'input-mask' */));

export type InputMaskTestId = ConvertLocatorToTestId<InputMaskLocator>;
export type InputMaskLocator = LocatorOfElement<void>;

export type InputMaskProps = import('react-input-mask').Props & Partial<Mark<InputMaskLocator>>;

export const InputMask = React.forwardRef<HTMLInputElement, InputMaskProps>(function InputMask(
  {mask, className, maskPlaceholder, ...restPropsWithMark},
  ref,
) {
  const locator = createLocator(restPropsWithMark);
  const {children, ...restProps} = removeMarkFromProperties(restPropsWithMark);

  return (
    <React.Suspense fallback={<Input {...restProps} className={className} ref={ref} disabled />}>
      <LazyInputMask
        {...restProps}
        className={cn(className, styles.input)}
        {...locator()}
        inputRef={ref}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
      >
        {children}
      </LazyInputMask>
    </React.Suspense>
  );
});
