import {Grid} from '@joomcode/joom-ui/Grid';
import {useQuestionnaireNavigation} from 'domain/assessment/questionnaire/hooks/useQuestionnaireNavigation';
import {UserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import {QuestionnaireMetaPanel} from 'domain/assessment/questionnaire/widgets/MetaPanel';
import {QuestionnairePage} from 'domain/assessment/questionnaire/widgets/Page';
import {QuestionnaireSubmitPanel} from 'domain/assessment/questionnaire/widgets/SubmitPanel';
import React from 'react';
import styles from './styles.css';

type Props = {
  questionnaire: UserQuestionnaire;
};

export function Questionnaire({questionnaire}: Props) {
  const {currentPage} = useQuestionnaireNavigation();

  if (!currentPage) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Grid>
        <Grid.Item xl={8}>
          <QuestionnairePage page={currentPage} />
        </Grid.Item>
        <Grid.Item xl={4}>
          <QuestionnaireMetaPanel questionnaire={questionnaire} />
        </Grid.Item>
      </Grid>
      <QuestionnaireSubmitPanel />
    </div>
  );
}
