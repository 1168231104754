import {Panel} from '@joomcode/joom-ui/Panel';
import {Markdown} from 'components/ui/Markdown';
import {Description} from 'domain/assessment/questionnaire/model/entity/description';
import React from 'react';
import styles from './styles.css';

type Props = {
  data: Description;
};

export function QuestionnaireDescriptionEntity({data: {backgroundColor, emoji, text}}: Props) {
  const style = backgroundColor ? {backgroundColor} : undefined;

  return (
    <Panel>
      <div className={styles.root} style={style}>
        {emoji && <div className={styles.emoji}>{emoji}</div>}
        <Markdown source={text} />
      </div>
    </Panel>
  );
}
