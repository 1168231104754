import {Error} from '@joomcode/joom-ui/FormControl/Error';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export function DataFilterLoadingError() {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <Error message={intl.formatMessage(messages.loadingError)} />
    </div>
  );
}
