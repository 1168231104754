import {ParticipantRoleId} from 'domain/assessment/cycle/model/participantRole';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Reviewing',
    description: '[title] Assessment user questionnaire meta panel: title',
  },
});

export const roles = defineMessages<ParticipantRoleId>({
  [ParticipantRoleId.MANAGER]: {
    defaultMessage: 'Oneself',
    description: 'Reviewee role',
  },
  [ParticipantRoleId.SUBORDINATE]: {
    defaultMessage: 'Reporting manager',
    description: 'Reviewee role',
  },
  [ParticipantRoleId.UPPER_MANAGER]: {
    defaultMessage: 'Manager',
    description: 'Reviewee role',
  },
});
