import {mockCycleLite} from 'domain/assessment/cycle/model/lite/mock';
import {mockParticipantCount} from 'domain/assessment/cycle/model/participantCount/mock';
import faker from 'faker';
import {ListedCycle} from '.';

export function mockListedCycles(): ListedCycle[] {
  return Array.from({length: faker.random.number({min: 2, max: 10})}, () => mockListedCycle());
}

export function mockListedCycle(data: Partial<ListedCycle> = {}): ListedCycle {
  return {
    ...mockCycleLite(data),
    participantCounts: Array.from({length: 3}, () => mockParticipantCount()),
    ...data,
  };
}
