import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Duty pending change warning dialog',
    description: 'Pending change dialog aria-label',
  },
  title: {
    defaultMessage: 'Pending change',
    description: '[title] Pending change dialog title',
  },
  text: {
    defaultMessage:
      'There is a pending duty rotation change scheduled for {date}.{br}Submitting a new change will terminate the previous one.',
    description: 'Pending change dialog text',
  },
  buttonContinue: {
    defaultMessage: 'Continue',
    description: '[button] Pending change dialog button',
  },
  buttonBack: {
    defaultMessage: 'Go back',
    description: '[button] Pending change dialog button',
  },
});
