import {isEnumValue} from '@joomcode/deprecated-utils/ts-enum';
import {EmptyDash} from '@joomcode/joom-ui/EmptyDash';
import {formatHumanDate, HumanDateTime} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as MessageIcon} from '@joomcode/joom-ui/icons/core/message.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {StyledLink} from 'components/ui/StyledLink';
import {CompensationRequest} from 'domain/compensations/request/model';
import {CompensationRequestReason} from 'domain/compensations/request/model/reason';
import {formatCompensationRequestReason} from 'domain/compensations/request/model/reason/formatter';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {
  getAdditionalInformationLabelByReason,
  getEventDatesLabelByReason,
} from 'domain/compensations/request/utils/getMessageByReason';
import {groupStays} from 'domain/compensations/request/utils/groupStays';
import {CommentFromAdmin} from 'domain/compensations/request/widgets/CommentFromAdmin';
import {CompensationRequestStatusBadge} from 'domain/compensations/request/widgets/StatusBadge';
import {CountryCode} from 'domain/countryCode/model';
import {Permission} from 'domain/permission/model';
import {UserLink} from 'domain/user/widgets/Link';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {getCountryName} from 'utils/country/name';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  compensationRequest: CompensationRequest;
};

export function CompensationRequestSummary({
  compensationRequest: {
    actualCompensationPaid,
    approximateTripCost,
    admins,
    approver,
    commentFromAdmin,
    comments,
    createdTime,
    expectedAllowance,
    id,
    owner,
    reason,
    reasonDetails,
    rejectionComment,
    status,
    stays,
  },
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const formattedReason = useMemo(() => formatCompensationRequestReason(reason, intl), [reason, intl]);
  const canEditCommentFromAdmin = acl.hasPermission(Permission.COMPENSATION_WRITE_ADMIN);
  const stayGroups = useMemo(() => groupStays(stays), [stays]);

  return (
    <Panel>
      <Panel.Section>
        <Panel.Header title={intl.formatMessage(messages.generalInfoTitle)} />
        <Panel.Content withPadding>
          <div className={styles.generalInfo}>
            <ListedProperty label={intl.formatMessage(labels.status)}>
              <CompensationRequestStatusBadge status={status} />
              {status === CompensationRequestStatus.REJECTED && rejectionComment && (
                <span className={styles.commentIcon}>
                  <TooltipFaq placement='right' content={<MultilineText text={rejectionComment} />}>
                    <MessageIcon />
                  </TooltipFaq>
                </span>
              )}
            </ListedProperty>
            <ListedProperty label={intl.formatMessage(labels.owner)}>
              {owner ? (
                <StyledLink to={usersUrls.user({login: owner.login})} colored>
                  {intl.formatMessage(generalMessages.fullName, {
                    firstName: owner.firstName,
                    lastName: owner.lastName,
                  })}
                </StyledLink>
              ) : (
                <EmptyDash />
              )}
            </ListedProperty>
            <ListedProperty label={intl.formatMessage(labels.approvingManager)}>
              {approver ? (
                <StyledLink to={usersUrls.user({login: approver.login})} colored>
                  {intl.formatMessage(generalMessages.fullName, {
                    firstName: approver.firstName,
                    lastName: approver.lastName,
                  })}
                </StyledLink>
              ) : (
                <EmptyDash />
              )}
            </ListedProperty>
            <ListedProperty
              label={intl.formatMessage(labels.admins, {count: admins.length})}
              hint={intl.formatMessage(messages.adminsHint)}
            >
              {intl.formatList(
                admins.map((admin) => <UserLink key={admin.id} user={admin} withAvatar={false} colored />),
                {type: 'conjunction', style: 'narrow'},
              )}
            </ListedProperty>
            <ListedProperty label={intl.formatMessage(labels.createdTime)}>
              <HumanDateTime value={createdTime} format='short' />
            </ListedProperty>
            <ListedProperty label={intl.formatMessage(labels.reason)}>
              <MultilineText text={formattedReason} />
            </ListedProperty>
            {reasonDetails && isEnumValue(CompensationRequestReason, reason) && (
              <>
                <ListedProperty label={getAdditionalInformationLabelByReason(reason, intl)}>
                  <MultilineText text={reasonDetails.additionalInformation} />
                </ListedProperty>
                {reasonDetails.eventDates && (
                  <ListedProperty label={getEventDatesLabelByReason(reason, intl)}>
                    <MultilineText
                      text={intl.formatList(
                        reasonDetails.eventDates.map((value) => formatHumanDate({intl, value, utc: true})),
                        {type: 'conjunction', style: 'long'},
                      )}
                    />
                  </ListedProperty>
                )}
              </>
            )}
            {comments && (
              <ListedProperty label={intl.formatMessage(labels.comments)}>
                <MultilineText text={comments} />
              </ListedProperty>
            )}
            {(commentFromAdmin || canEditCommentFromAdmin) && (
              <ListedProperty label={intl.formatMessage(labels.commentFromAdmin)}>
                {canEditCommentFromAdmin ? (
                  <CommentFromAdmin id={id} value={commentFromAdmin} />
                ) : (
                  commentFromAdmin && <MultilineText text={commentFromAdmin} />
                )}
              </ListedProperty>
            )}
          </div>
        </Panel.Content>
      </Panel.Section>
      <Panel.Section>
        <Panel.Header title={intl.formatMessage(messages.routeTitle)} />
        <Panel.Content withPadding>
          {stayGroups.map(({countryCode, startDate, endDate, includedStays}) => (
            <div key={`${startDate}-${endDate}`} className={styles.stayGroup}>
              <div className={styles.stay}>
                {getCountryName(countryCode, intl)}
                <div className={styles.stayDates}>
                  {intl.formatMessage(messages.dateInterval, {
                    date1: formatHumanDate({intl, value: startDate, utc: true}),
                    date2: formatHumanDate({intl, value: endDate, utc: true}),
                  })}
                </div>
              </div>
              <div className={styles.cities}>
                {countryCode === CountryCode.CN &&
                  includedStays.map(({city, startDate, endDate}) => (
                    <div key={`${city}-${startDate}-${endDate}`} className={styles.stay}>
                      {city}
                      <div className={styles.stayDates}>
                        {intl.formatMessage(messages.dateInterval, {
                          date1: formatHumanDate({intl, value: startDate, utc: true}),
                          date2: formatHumanDate({intl, value: endDate, utc: true}),
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </Panel.Content>
      </Panel.Section>
      <Panel.Section>
        <Panel.Header title={intl.formatMessage(messages.moneyTitle)} />
        <Panel.Content withPadding>
          <div className={styles.money}>
            {approximateTripCost && (
              <ListedProperty label={intl.formatMessage(labels.approximateTripCost)}>
                <FormattedPrice value={approximateTripCost} />
              </ListedProperty>
            )}
            <ListedProperty label={intl.formatMessage(labels.allowance)}>
              {expectedAllowance.map((money, index) => (
                <React.Fragment key={money.currency}>
                  {index !== 0 && ' + '}
                  <FormattedPrice value={money} />
                </React.Fragment>
              ))}
            </ListedProperty>
            {actualCompensationPaid && (
              <ListedProperty label={intl.formatMessage(labels.actualCompensationPaid)}>
                <FormattedPrice value={actualCompensationPaid} />
              </ListedProperty>
            )}
          </div>
        </Panel.Content>
      </Panel.Section>
    </Panel>
  );
}
