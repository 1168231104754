import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Markdown} from 'components/ui/Markdown';
import {Duty} from 'domain/duty/model';
import {checkIsDutyManager} from 'domain/duty/utils/checkIsDutyManager';
import {checkIsDutyMember} from 'domain/duty/utils/checkIsDutyMember';
import {DutyAddOverrideButton} from 'domain/duty/widgets/AddOverrideButton';
import {DutyPanelHeader} from 'domain/duty/widgets/PanelHeader';
import {DutyTimeline} from 'domain/duty/widgets/Timeline';
import {DutyUpdateButton} from 'domain/duty/widgets/UpdateButton';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {Team} from 'domain/team/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  duty: Duty;
  team: Team;
};

export function DutyPanel({duty, team}: Props) {
  const intl = useIntl();
  const selfUser = useSelfUser();
  const isDutyManager = checkIsDutyManager({duty, team, selfUser});
  const isDutyMember = checkIsDutyMember({duty, selfUserId: selfUser?.id});
  const canEditSchedule = isDutyManager || isDutyMember;

  return (
    <Panel
      title={<DutyPanelHeader duty={duty} />}
      withPadding
      withSeparator
      toolbarTop={
        canEditSchedule && (
          <ButtonGroup spaced>
            <DutyUpdateButton dutyId={duty.id} teamId={team.id} />
            <DutyAddOverrideButton dutyId={duty.id} teamId={team.id} intent='ghost' />
          </ButtonGroup>
        )
      }
    >
      <DutyTimeline
        duty={{
          title: intl.formatMessage(messages.main),
          mainSchedule: duty.schedule.main,
          overrideSchedule: duty.schedule.overrides,
        }}
        timezoneOffset={0}
        dutyId={duty.id}
        teamId={team.id}
        canCreateOverrides={canEditSchedule}
        canDeleteOverrides={canEditSchedule}
        showOverrides={duty.schedule.overrides.length > 0}
      >
        <div className={styles.content}>
          <div>
            {intl.formatList(
              duty.slackUserGroups.map((group) => (
                <span className={styles.group} key={group.id}>
                  {`@${group.handle}`}
                </span>
              )),
              {type: 'conjunction', style: 'narrow'},
            )}
          </div>
          <Markdown source={duty.description} />
        </div>
      </DutyTimeline>
    </Panel>
  );
}
