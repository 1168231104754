import {ReactComponent as Icon} from '@joomcode/joom-ui/icons/circle/watchOutline.svg';
import React from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const formatDate = (date: string, intl: IntlShape) =>
  intl.formatDate(date, {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'utc',
  });

type Props = {
  start?: string;
  end: string;
};

export function Timing({start, end}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.dates}>
        {start
          ? intl.formatMessage(messages.range, {
              from: formatDate(start, intl),
              to: formatDate(end, intl),
            })
          : intl.formatMessage(messages.due, {date: formatDate(end, intl)})}
      </div>
    </div>
  );
}
