import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {mockOnError} from 'apiClient/mockOnError';
import {ListedCycle, listedCycleSchema} from 'domain/assessment/cycle/model/listed';
import {mockListedCycles} from 'domain/assessment/cycle/model/listed/mock';

export const getAll = async (): Promise<ListedCycle[]> => {
  const {data} = await intranetApi.get('/v1/assessment/admin/cycles/getAll').catch(mockOnError(mockListedCycles));

  return array(listedCycleSchema).runWithException(data);
};
