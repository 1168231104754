import {
  array,
  boolean,
  constant,
  dateFromString,
  ExtractSchemaType,
  intersection,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {dutyLiteSchema} from 'domain/duty/model/lite';
import {employmentTypeSchema} from 'domain/employmentType/model';
import {Language} from 'domain/language/model';
import {officeSeatSchema} from 'domain/officeMap/seat/model';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {ShirtSize} from 'domain/shirtSize/model';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {timeOffSchema} from 'domain/timeOff/userTimeOff/model';
import {formattedDateSchema, formattedMonthAndDaySchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {imageBundleSchema} from 'models/system/imageBundle';
import {phoneSchema} from 'models/system/phone';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {userMembershipsSchema} from './memberships';
import {userSocialMediaLinksSchema} from './socialMediaLinks';

export const userIdSchema = idSchema;

export const userSchema = object({
  avatarBundle: optional(imageBundleSchema),
  countryOfTimeOffPolicy: optional(oneOfEnum(TimeOffPolicy)),
  department: optional(string()),
  division: optional(string()),
  email: string(),
  firstName: string(),
  githubLogin: optional(string()),
  hidden: optional(boolean()),
  hireDate: optional(formattedDateSchema),
  id: userIdSchema,
  isOutstaff: optionalWithDefault(false, boolean()),
  isSalaryManager: optionalWithDefault(false, boolean()),
  isTerminated: optionalWithDefault(false, boolean()),
  jobTitle: optional(string()),
  lastName: string(),
  location: optional(string()),
  login: string(),
  managerId: optional(userIdSchema),
  monthAndDayOfBirth: optional(formattedMonthAndDaySchema),
  officePolicy: optional(oneOfEnum(OfficePolicy)),
  offTime: optional(array(timeOffSchema)),
  onDuty: optional(dutyLiteSchema),
  phone: optional(phoneSchema),
  roleIds: optionalWithDefault([], array(idSchema)),
  seat: optional(officeSeatSchema),
  slackDisplayName: optional(string()),
  slackLink: optional(string()),
  verifiedGithubLogin: optional(string()),
  workingRemotely: optionalWithDefault(false, boolean()),
}).map((user) => ({...user, fullName: `${user.firstName} ${user.lastName}`}));

export const userFullSchema = intersection(
  userSchema,
  object({
    about: optional(string()),
    allocationRule: optional(string()),
    ancestorIds: optionalWithDefault([], array(userIdSchema)),
    createdTime: optional(dateFromString()),
    employmentType: optional(employmentTypeSchema),
    extraPhone: optional(phoneSchema),
    isFull: constant(true),
    manager: optional(userSchema),
    memberships: optional(userMembershipsSchema),
    middleName: optional(string()),
    otherLanguages: optional(array(oneOfEnum(Language))),
    personalEmail: optional(string()),
    pretendingToBeNormal: optional(boolean()),
    primaryLanguage: optional(oneOfEnum(Language)),
    shirtSize: optional(oneOfEnum(ShirtSize)),
    socialMediaLinks: optional(userSocialMediaLinksSchema),
    subordinates: optional(array(userSchema)),
    updatedTime: optional(dateFromString()),
  }),
);

export type User = ExtractSchemaType<typeof userSchema>;
export type UserFull = ExtractSchemaType<typeof userFullSchema>;
export type UserId = ExtractSchemaType<typeof userIdSchema>;
