import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {mockDescription} from 'domain/assessment/questionnaire/model/entity/description/mock';
import {mockQuestion} from 'domain/assessment/questionnaire/model/entity/question/mock';
import {mockTitle} from 'domain/assessment/questionnaire/model/entity/title/mock';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';
import faker from 'faker';
import {ContentEntity} from '.';

export function mockContentEntity(): ContentEntity {
  const type = faker.random.arrayElement(getEnumValues(EntityType).filter((t) => t !== EntityType.PAGE));

  switch (type) {
    case EntityType.TITLE:
      return {
        type,
        data: mockTitle(),
      };
    case EntityType.DESCRIPTION:
      return {
        type,
        data: mockDescription(),
      };
    default:
      return {
        type,
        data: mockQuestion(),
      };
  }
}
