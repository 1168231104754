import {assertNever} from '@joomcode/deprecated-utils/types';
import debounce from 'lodash/debounce';
import {useCallback, useEffect, useState, type Ref} from 'react';
import {Direction, isHorizontalDirection} from '../utils/direction';

type Scrollbox = {
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  scrollHeight: number;
  clientHeight: number;
  clientWidth: number;
};

export type ScrollboxApi = {
  scrollbox?: Scrollbox;
  isScrollableTo(dir: Direction): boolean;
  scrollToDirection(dir: Direction): void;
  scrollToPosition(position: number): void;
};

type UseScrollbox<T> = [Ref<T>, ScrollboxApi];

export function useScrollbox<T extends HTMLElement>(): UseScrollbox<T> {
  const [element, setElement] = useState<T | null>(null);
  const [scrollbox, setScrollbox] = useState<Scrollbox | undefined>(undefined);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const update = () => {
      setScrollbox({
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft,
        scrollWidth: element.scrollWidth,
        scrollHeight: element.scrollHeight,
        clientHeight: element.clientHeight,
        clientWidth: element.clientWidth,
      });
    };

    update();

    const listener = debounce(() => update(), 100);
    element.addEventListener('scroll', listener);
    window.addEventListener('resize', listener);

    return () => {
      element.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, [element]);

  const isScrollableTo = useCallback(
    (dir: Direction) => {
      if (!scrollbox) {
        return false;
      }

      switch (dir) {
        case Direction.LEFT:
          return scrollbox.scrollLeft > 0;
        case Direction.RIGHT:
          return scrollbox.scrollLeft < scrollbox.scrollWidth - scrollbox.clientWidth;
        case Direction.TOP:
          return scrollbox.scrollTop > 0;
        case Direction.BOTTOM:
          return scrollbox.scrollTop < scrollbox.scrollHeight - scrollbox.clientHeight;
        default:
          assertNever(dir);
          throw new Error(`Unknown dir: ${dir}`);
      }
    },
    [scrollbox],
  );

  const scrollToDirection = useCallback(
    (dir: Direction, behavior: ScrollToOptions['behavior'] = 'smooth') => {
      if (!element || !scrollbox) {
        return;
      }

      if (isHorizontalDirection(dir)) {
        const diff = Math.round(scrollbox.clientWidth / 2);
        element.scrollTo({
          behavior,
          left: scrollbox.scrollLeft + (dir === Direction.LEFT ? -diff : diff),
        });
      } else {
        const diff = Math.round(scrollbox.clientHeight / 2);
        element.scrollTo({
          behavior,
          top: scrollbox.scrollTop + (dir === Direction.TOP ? -diff : diff),
        });
      }
    },
    [element, scrollbox],
  );

  const scrollToPosition = useCallback(
    (position: number, behavior: ScrollToOptions['behavior'] = 'instant') => {
      if (!element) {
        return;
      }
      element.scrollTo({left: position, behavior});
    },
    [element],
  );

  return [setElement, {scrollbox, isScrollableTo, scrollToDirection, scrollToPosition}];
}
