import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  userId: {
    defaultMessage: 'Joomer',
    description: 'Stock option filter label',
  },
  userFilterLabel: {
    defaultMessage: '{fullName} ({login})',
    description: 'Stock option filters: User autocomplete item label',
  },
});
