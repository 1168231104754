import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {RouteConfig} from 'react-router-config';
import {config as assessmentRouteConfig} from 'routes/assessment';
import {config as compensationsRoutes} from 'routes/compensations';
import {config as hrConsoleConfig} from 'routes/hrConsole';
import {config as jdmRoutes} from 'routes/jdm';
import {config as legalEntitiesRoutes} from 'routes/legalEntities';
import {config as mainRouteConfig} from 'routes/main';
import {config as managerConsoleConfig} from 'routes/managerConsole';
import {config as meRouteConfig} from 'routes/me';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {config as offerRoutes} from 'routes/offer';
import {config as officeMapRoutes} from 'routes/officeMap';
import {config as outstaffUsersConfig} from 'routes/outstaffUsers';
import {config as redirectorRoutes} from 'routes/redirector';
import {config as rtconfRoutes} from 'routes/rtconf';
import {config as secureRolesRouteConfig} from 'routes/secureRoles';
import {config as sensitiveDataRoutes} from 'routes/sensitiveData';
import {config as stockOptionsConfig} from 'routes/stockOptions';
import {config as teamsRoutes} from 'routes/teams';
import {legacyTeamsRedirects} from 'routes/teams/redirects';
import {config as timeOffRequestsRoutes} from 'routes/timeOff';
import {config as unauthenticatedRouteConfig} from 'routes/unauthenticated';
import {config as userRolesRoutes} from 'routes/userRoles';
import {config as usersRouteConfig} from 'routes/users';
import {Application} from './Application';

export const routesConfig: RouteConfig[] = [
  {
    component: Application,
    routes: [
      // Page routes
      mainRouteConfig,
      meRouteConfig,
      usersRouteConfig,
      rtconfRoutes,
      compensationsRoutes,
      legalEntitiesRoutes,
      ...legacyTeamsRedirects,
      officeMapRoutes,
      teamsRoutes,
      userRolesRoutes,
      redirectorRoutes,
      timeOffRequestsRoutes,
      secureRolesRouteConfig,
      unauthenticatedRouteConfig,
      jdmRoutes,
      stockOptionsConfig,
      offerRoutes,
      sensitiveDataRoutes,
      hrConsoleConfig,
      managerConsoleConfig,
      outstaffUsersConfig,
      assessmentRouteConfig,

      // It’s fallback route, it must be last
      notFoundRouteConfig,
    ].filter(isNotNullish),
  },
];
