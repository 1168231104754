import {DataState} from '@joomcode/deprecated-utils/dataState';
import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileDropArea} from '@joomcode/joom-ui/FileDropArea';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {cycleApi} from 'domain/assessment/cycle/api';
import {CycleId} from 'domain/assessment/cycle/model/lite';
import {useFileUpload} from 'hooks/useFileUpload';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {assessmentUrls} from 'routes/assessment/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

const ACCEPTED_MIME_TYPES = [mimeType.excel.xlsx];

export function CycleImportPanel() {
  const intl = useIntl();
  const history = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);
  const onError = useCallback(({message}: Error) => setError(message), [setError]);
  const onComplete = useCallback(
    (id: CycleId) => {
      toaster.success(intl.formatMessage(messages.success));
      history.push(assessmentUrls.cycle({id}));
    },
    [intl],
  );

  const {onAttach, dataState} = useFileUpload<void, CycleId>({
    upload: cycleApi.importCycle,
    params: undefined,
    onComplete,
    onError,
  });

  const onChange = useCallback(
    (files: File[]) => {
      const file = files[0];
      if (!file) {
        return;
      }
      if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        setError(intl.formatMessage(messages.wrongFileType));
        return;
      }
      setError(undefined);
      onAttach(file);
    },
    [onAttach, setError, intl],
  );

  return (
    <Panel className={styles.root}>
      <Panel.Content withPadding>
        {intl.formatMessage(messages.annotation, {
          link: (text) => (
            <StyledExternalLink
              colored
              href='https://docs.google.com/spreadsheets/d/1wzNpP0XOIRnO8SRd657t8nlMbMutXtmWsjm5tyr4A5c/edit?gid=0#gid=0'
            >
              {text}
            </StyledExternalLink>
          ),
        })}
      </Panel.Content>
      {error && (
        <Panel.Content withPadding>
          <IntentionedText intent='negative'>{error}</IntentionedText>
        </Panel.Content>
      )}
      <Panel.Content withPadding>
        <FileDropArea
          accept={ACCEPTED_MIME_TYPES}
          multiple={false}
          onChange={onChange}
          processing={dataState === DataState.LOADING}
        />
      </Panel.Content>
    </Panel>
  );
}
