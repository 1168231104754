import {constant, ExtractSchemaType, object, oneOf} from '@joomcode/deprecated-utils/jsonValidation';
import {DescriptionEntity, descriptionSchema} from 'domain/assessment/questionnaire/model/entity/description';
import {QuestionEntity, questionSchema} from 'domain/assessment/questionnaire/model/entity/question';
import {TitleEntity, titleSchema} from 'domain/assessment/questionnaire/model/entity/title';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';

export const contentEntitySchema = oneOf<TitleEntity | DescriptionEntity | QuestionEntity>(
  object({
    type: constant(EntityType.TITLE),
    data: titleSchema,
  }),
  object({
    type: constant(EntityType.DESCRIPTION),
    data: descriptionSchema,
  }),
  object({
    type: constant(EntityType.QUESTION),
    data: questionSchema,
  }),
);

export type ContentEntity = ExtractSchemaType<typeof contentEntitySchema>;
