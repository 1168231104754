import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {formatCycleType} from 'domain/assessment/cycle/model/type/formatter';
import {useUserQuestionnaire} from 'domain/assessment/questionnaire/hooks/useUserQuestionnaire';
import {QuestionnaireNavigationProvider} from 'domain/assessment/questionnaire/widgets/Navigation';
import {Questionnaire} from 'domain/assessment/questionnaire/widgets/Questionnaire';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';

export function UserQuestionnairePage() {
  const intl = useIntl();
  const {id} = useTypedParams({id: string()});
  const managerAssessmentFeature = useFeature(Feature.MANAGER_ASSESSMENT);
  const {data, dataState, clientErrorStatus} = useUserQuestionnaire(id);

  if (!managerAssessmentFeature.isAvailable || !id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} entityError={clientErrorStatus} state={dataState}>
      {(questionnaire) => (
        <Page title={formatCycleType(questionnaire.cycle.type, intl)}>
          <QuestionnaireNavigationProvider questionnaire={questionnaire}>
            <Questionnaire questionnaire={questionnaire} />
          </QuestionnaireNavigationProvider>
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
