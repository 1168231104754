import {CompensationRequest} from 'domain/compensations/request/model';
import {
  CompensationRequestsFilterValue,
  CompensationRequestsFilterValues,
  compensationRequestsFilterValuesSchema,
} from 'domain/compensations/request/model/filters';
import {CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {checkRequestFitsFilters} from 'domain/compensations/request/utils/checkRequestFitsFilters';
import {getAvailableFilters} from 'domain/compensations/request/utils/getAvailableFilters';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {User} from 'domain/user/model';
import {checkHasIndirectSubordinates} from 'domain/user/utils/checkHasIndirectSubordinates';
import {useStoredFilters} from 'hooks/useStoredFilters';
import {useMemo} from 'react';

type Options = {
  mode: CompensationRequestsMode;
  requestsByMode: Record<CompensationRequestsMode, CompensationRequest[]>;
  users: User[];
};

type ReturnShape = {
  availableFilters: CompensationRequestsFilterValue[];
  filteredRequests: CompensationRequest[];
  filterValues: CompensationRequestsFilterValues;
  onFiltersChange: (values: CompensationRequestsFilterValues) => void;
};

export function useCompensationRequestFilters({mode, requestsByMode, users}: Options): ReturnShape {
  const selfUserId = useSelfUserId();
  const {filterValues, setFilterValues} = useStoredFilters<CompensationRequestsFilterValues>({
    id: 'compensationRequests.filters',
    initialValues: {},
    valuesSchema: compensationRequestsFilterValuesSchema,
  });

  const hasIndirectSubordinates = useMemo(() => checkHasIndirectSubordinates(selfUserId, users), [selfUserId, users]);
  const availableFilters = useMemo(
    () => getAvailableFilters(mode, hasIndirectSubordinates),
    [mode, hasIndirectSubordinates],
  );
  const filteredRequests = useMemo(
    () =>
      requestsByMode[mode].filter((request) =>
        checkRequestFitsFilters({selfUserId, request, filterValues, availableFilters}),
      ),
    [availableFilters, filterValues, mode, requestsByMode, selfUserId],
  );

  return {
    availableFilters,
    filteredRequests,
    filterValues,
    onFiltersChange: setFilterValues,
  };
}
