import {mockParticipantRole} from 'domain/assessment/cycle/model/participantRole/mock';
import faker from 'faker';
import {ParticipantCount} from '.';

export function mockParticipantCount(data: Partial<ParticipantCount> = {}): ParticipantCount {
  return {
    count: faker.random.number({min: 0, max: 100}),
    role: mockParticipantRole(),
    ...data,
  };
}
