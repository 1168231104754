import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {
  array,
  dateFromString,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {compensationReportSchema} from 'domain/compensations/report/model';
import {staySchema} from 'domain/compensations/stay/model';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {moneySchema} from 'domain/money/model';
import {userSchema} from 'domain/user/model';
import {nonEmptyArray} from 'utils/jsonValidation/nonEmptyArray';
import {CompensationRequestStatus} from './status';

export const compensationRequestIdSchema = idSchema;
export const compensationRequestSchema = object({
  actualCompensationPaid: optional(moneySchema),
  admins: array(userSchema),
  approximateTripCost: optional(moneySchema),
  approver: userSchema,
  commentFromAdmin: optional(string()),
  comments: optional(string()),
  createdTime: dateFromString(),
  endDate: dateFromString(),
  expectedAllowance: array(moneySchema),
  id: compensationRequestIdSchema,
  legalEntity: legalEntitySchema,
  owner: userSchema,
  reason: string(),
  reasonDetails: optional(
    object({
      additionalInformation: string(),
      eventDates: optional(array(dateFromString())),
    }),
  ),
  rejectionComment: optional(string()),
  report: optional(compensationReportSchema),
  startDate: dateFromString(),
  status: oneOfEnum(CompensationRequestStatus),
  stays: nonEmptyArray(staySchema),
});

export const compensationRequestCreationConfigSchema = object({
  comments: optional(string()),
  reason: string(),
  reasonDetails: object({
    additionalInformation: string(),
    eventDates: optional(array(dateFromString())),
  }),
  stays: array(staySchema),
});

export type CompensationRequestId = ExtractSchemaType<typeof compensationRequestIdSchema>;
export type CompensationRequest = ExtractSchemaType<typeof compensationRequestSchema>;
export type CompensationRequestCreationConfig = ExtractSchemaType<typeof compensationRequestCreationConfigSchema>;
