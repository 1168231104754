import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  success: {
    defaultMessage: '{dutyName} updated',
    description: 'Duty form success message',
  },
  newSchedule: {
    defaultMessage: 'New schedule',
    description: 'Duty form timeline group',
  },
  currentSchedule: {
    defaultMessage: 'Current schedule',
    description: 'Duty form timeline group',
  },
});
