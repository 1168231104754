import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {
  bindValidatorOptions,
  CommonFieldProps,
  composeValidators,
  getFieldValidator,
  useField,
  validateFilled,
} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {MultiSelect} from '@joomcode/joom-ui/Select/MultiSelect';
import {useSlackUserGroups} from 'domain/duty/hooks/useSlackUserGroups';
import {SlackUserGroup, SlackUserGroupId} from 'domain/duty/model/slackUserGroup';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

export type Props = Omit<CommonFieldProps<SlackUserGroupId[]>, 'initialValue'> & {
  initialValue?: SlackUserGroup[];
};

export function FieldSlackUserGroups({
  name,
  label,
  hint,
  required,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const {data, dataState} = useSlackUserGroups();
  const dataWithInitialValues = useMemo(() => (initialValue ? [...initialValue, ...data] : data), [data, initialValue]);
  const items = useMemo(() => dataWithInitialValues.map(({id}) => id), [dataWithInitialValues]);
  const initialIds = useMemo(() => initialValue?.map(({id}) => id) || [], [initialValue]);
  const nameById = useMemo(
    () =>
      arrayToObject(
        dataWithInitialValues,
        ({id}) => id,
        ({name}) => name,
      ),
    [dataWithInitialValues],
  );
  const itemToString = useCallback((id: SlackUserGroupId) => nameById[id], [nameById]);
  const validateRequired = required ? bindValidatorOptions(validateFilled, {checkEmptyArrays: true}) : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {
    input: {value, ...input},
    meta,
  } = useField<SlackUserGroupId[]>(name, {
    initialValue: initialIds,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });
  const disabled = meta.submitting || restProps.disabled || dataState !== DataState.LOADED;
  const loadingError = dataState === DataState.FAILED ? intl.formatMessage(messages.loadingFailed) : undefined;

  const handleChange = useCallback(
    (value: SlackUserGroupId[]) => {
      // mark as touched
      input.onFocus();
      input.onBlur();
      input.onChange(value);
    },
    [input],
  );

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled={disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages}) || loadingError}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <MultiSelect<SlackUserGroupId>
          items={items}
          itemToString={itemToString}
          renderOption={itemToString}
          value={value || []}
          {...formControlProps}
          {...input}
          {...restProps}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </FormControl>
  );
}
