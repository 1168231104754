import {Callout} from '@joomcode/joom-ui/Callout';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  date: number;
};

export function DutyPendingChangeCallout({date}: Props) {
  const intl = useIntl();

  return (
    <Callout intent='primary' inline withIcon>
      {intl.formatMessage(messages.text, {
        date: <HumanDate value={date} format='short' utc />,
      })}
    </Callout>
  );
}
