import {Duty} from 'domain/duty/model';
import {Team} from 'domain/team/model';
import {UserFull} from 'domain/user/model';

type Options = {
  duty: Duty;
  team: Team;
  selfUser: UserFull | undefined;
};

export function checkIsDutyManager({duty, team, selfUser}: Options) {
  if (!selfUser) {
    return false;
  }

  const subordinates = selfUser.subordinates ?? [];
  const teamOwnerIds = team.members.activeMembers
    .filter(({memberships}) => memberships.some(({role}) => role.isHead))
    .map(({user}) => user.id);

  const isDutyOwner = selfUser.id === duty.owner.id;
  const isDirectManagerOfDutyOwner = subordinates.some((subordinate) => subordinate.id === duty.owner.id);
  const isTeamHead = team.members.activeMembers.some(
    ({memberships, user}) => user.id === selfUser.id && memberships.some(({role}) => role.isHead),
  );
  const isDirectManagerOfTeamHead = subordinates.some((subordinate) => teamOwnerIds.includes(subordinate.id));

  return isDutyOwner || isDirectManagerOfDutyOwner || isTeamHead || isDirectManagerOfTeamHead;
}
