import {ScrollboxApi} from '@joomcode/joom-ui/Timeline';
import {InitialScrollConfig} from '@joomcode/joom-ui/Timeline/types';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {getScheduleItemOnDate} from 'domain/duty/utils/getScheduleItemOnDate';
import {useCallback, useMemo} from 'react';

export function useInitialScroll(
  scrollboxApi: ScrollboxApi,
  schedule: ScheduleItem[],
  initialScrollDate?: Date,
): InitialScrollConfig | undefined {
  const scrollTo = useCallback(
    (position: number) => {
      scrollboxApi.scrollToPosition(position);
    },
    [scrollboxApi.scrollToPosition],
  );

  return useMemo(() => {
    if (schedule.length === 0) {
      return undefined;
    }

    const scheduleItem = getScheduleItemOnDate(schedule, initialScrollDate || new Date()) ?? schedule[0];
    return {
      date: new Date(scheduleItem.startDate),
      scrollTo,
    };
  }, [schedule, scrollTo, initialScrollDate]);
}
