import {ContentEntity} from 'domain/assessment/questionnaire/model/entity/content';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';
import {QuestionnaireDescriptionEntity} from 'domain/assessment/questionnaire/widgets/DescriptionEntity';
import {QuestionnaireTitleEntity} from 'domain/assessment/questionnaire/widgets/TitleEntity';
import React from 'react';

type Props = {
  entity: ContentEntity;
};

export function QuestionnaireContentEntity({entity}: Props) {
  switch (entity.type) {
    case EntityType.TITLE:
      return <QuestionnaireTitleEntity data={entity.data} />;
    case EntityType.DESCRIPTION:
      return <QuestionnaireDescriptionEntity data={entity.data} />;
    default:
      return null;
  }
}
