import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {mockGridQuestion} from 'domain/assessment/questionnaire/model/entity/question/grid/mock';
import {QuestionType} from 'domain/assessment/questionnaire/model/entity/question/type';
import faker from 'faker';
import {Question} from '.';

export function mockQuestion(data: Partial<Question> = {}): Question {
  return {
    answerComment: faker.lorem.sentence(),
    answerRequired: faker.random.boolean(),
    commentAllowed: faker.random.boolean(),
    data: mockGridQuestion(),
    description: faker.lorem.sentence(),
    text: faker.lorem.sentence(),
    type: faker.random.arrayElement(getEnumValues(QuestionType)),
    ...data,
  };
}
