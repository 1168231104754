import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {teamIdSchema} from 'domain/team/model/id';

export const dutyIdSchema = idSchema;
export const dutyLiteSchema = object({
  id: dutyIdSchema,
  name: string(),
  teamId: teamIdSchema,
}).map((duty) => ({
  ...duty,
  fullName: duty.name.toLocaleLowerCase().includes('duty') ? duty.name : `${duty.name} duty`,
}));

export type DutyId = ExtractSchemaType<typeof dutyIdSchema>;
export type DutyLite = ExtractSchemaType<typeof dutyLiteSchema>;
