import {DashedButton} from 'components/ui/DashedButton';
import {addDays, addHours} from 'date-fns';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {getScheduleItemOnDate} from 'domain/duty/utils/getScheduleItemOnDate';
import {FormState} from 'domain/duty/widgets/OverrideForm';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';
import {formatTime} from './utils';

type Props = {
  change: (name: keyof FormState, value: string) => void;
  schedule: ScheduleItem[];
};

export function FastOptions({change, schedule}: Props) {
  const intl = useIntl();
  const currentShift = useMemo(() => getScheduleItemOnDate(schedule, new Date()), [schedule]);

  const setCurrentStartTime = useCallback(() => {
    const now = new Date();

    change('startDate', now.toISOString());
    change('startTime', formatTime(now, intl));
  }, [intl, change]);

  const handleOneHourClick = useCallback(() => {
    const now = new Date();

    setCurrentStartTime();
    change('endDate', now.toISOString());
    change('endTime', formatTime(addHours(now, 1), intl));
  }, [intl, change, setCurrentStartTime]);

  const handleThreeHoursClick = useCallback(() => {
    const now = new Date();

    setCurrentStartTime();
    change('endDate', now.toISOString());
    change('endTime', formatTime(addHours(now, 3), intl));
  }, [intl, change, setCurrentStartTime]);

  const handleUntilMorningClick = useCallback(() => {
    const now = new Date();

    setCurrentStartTime();
    change('endDate', addDays(now, 1).toISOString());
    change('endTime', '09:00:00');
  }, [intl, change, setCurrentStartTime]);

  const handleUntilCurrentShiftClick = useCallback(() => {
    if (!currentShift) {
      return;
    }

    setCurrentStartTime();
    change('endDate', currentShift.endDate);
    change('endTime', formatTime(new Date(currentShift.endDate), intl));
  }, [intl, change, currentShift, setCurrentStartTime]);

  return (
    <div className={styles.root}>
      <label className={styles.label}>{intl.formatMessage(messages.label)}</label>
      <DashedButton className={styles.button} onClick={handleOneHourClick}>
        {intl.formatMessage(messages.oneHour)}
      </DashedButton>
      <DashedButton className={styles.button} onClick={handleThreeHoursClick}>
        {intl.formatMessage(messages.threeHours)}
      </DashedButton>
      <DashedButton className={styles.button} onClick={handleUntilMorningClick}>
        {intl.formatMessage(messages.untilMorning)}
      </DashedButton>
      {currentShift && (
        <DashedButton className={styles.button} onClick={handleUntilCurrentShiftClick}>
          {intl.formatMessage(messages.untilCurrentShift)}
        </DashedButton>
      )}
    </div>
  );
}
