import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CycleStatus} from 'domain/assessment/cycle/model/status';
import {CycleType} from 'domain/assessment/cycle/model/type';
import faker from 'faker';
import {CycleLite} from '.';

export function mockCycleLite(data: Partial<CycleLite> = {}): CycleLite {
  return {
    createdAt: faker.date.past().toISOString().slice(0, 10),
    endCycleAt: faker.date.future().toISOString().slice(0, 10),
    id: createMockId(),
    name: `${faker.company.bsNoun()} assesment Q${faker.random.number({min: 1, max: 4})}`,
    startCycleAt: faker.date.recent().toISOString().slice(0, 10),
    status: faker.random.arrayElement(getEnumValues(CycleStatus)),
    type: faker.random.arrayElement(getEnumValues(CycleType)),
    ...data,
  };
}
