import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  approvingManager: {
    defaultMessage: 'Approving manager',
    description: 'Compensation request summary label',
  },
  createdTime: {
    defaultMessage: 'Created',
    description: 'Compensation request summary label',
  },
  owner: {
    defaultMessage: 'Joomer',
    description: 'Compensation request summary label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Compensation request summary label',
  },
  reason: {
    defaultMessage: 'Purpose',
    description: 'Compensation request summary label',
  },
  comments: {
    defaultMessage: 'Comments',
    description: 'Compensation request summary label',
  },
  allowance: {
    defaultMessage: 'Approximate sum of daily allowance',
    description: 'Compensation request summary label',
  },
  approximateTripCost: {
    defaultMessage: 'Approximate sum of tickets and hotel reservations',
    description: 'Compensation request summary label',
  },
  actualCompensationPaid: {
    defaultMessage: 'Compensation paid',
    description: 'Compensation request summary label',
  },
  commentFromAdmin: {
    defaultMessage: 'Comment from admin',
    description: 'Compensation request summary label',
  },
  admins: {
    defaultMessage: 'Approving {count, plural, one {admin} other {admins}}',
    description: 'Compensation request summary label',
  },
});

export const messages = defineMessages({
  dateInterval: {
    defaultMessage: '{date1} to {date2}',
    description: 'Date interval',
  },
  generalInfoTitle: {
    defaultMessage: 'General info',
    description: '[title] Compensation request summary section title',
  },
  moneyTitle: {
    defaultMessage: 'Money',
    description: '[title] Compensation request summary section title',
  },
  routeTitle: {
    defaultMessage: 'Route',
    description: '[title] Compensation request summary section title',
  },
  adminsHint: {
    defaultMessage:
      'After approval from the manager, the application must be approved by one of the Office Care managers.',
    description: 'Compensation request summary label hint',
  },
});
