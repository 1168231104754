import {defineMessages} from 'react-intl';
import {UserQuestionnaireStatus} from '.';

export const messages = defineMessages<UserQuestionnaireStatus>({
  [UserQuestionnaireStatus.NOT_STARTED]: {
    defaultMessage: 'Not started',
    description: 'Assessment user questionnaire status',
  },
  [UserQuestionnaireStatus.IN_PROGRESS]: {
    defaultMessage: 'In progress',
    description: 'Assessment user questionnaire status',
  },
  [UserQuestionnaireStatus.COMPLETED]: {
    defaultMessage: 'Completed',
    description: 'Assessment user questionnaire status',
  },
});
