import {mockContentEntity} from 'domain/assessment/questionnaire/model/entity/content/mock';
import {EntityType} from 'domain/assessment/questionnaire/model/entity/type';
import faker from 'faker';
import {Page, PageEntity} from '.';

export function mockPage(data: Partial<Page> = {}): Page {
  return {
    name: faker.lorem.words(2),
    entities: Array.from({length: faker.random.number({min: 12, max: 18})}, () => mockContentEntity()),
    ...data,
  };
}

export function mockPageEntity(data: Partial<PageEntity> = {}): PageEntity {
  return {
    type: EntityType.PAGE,
    data: mockPage(),
    ...data,
  };
}
