import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {CompensationRequest, compensationRequestSchema} from 'domain/compensations/request/model';
import {Permission} from 'domain/permission/model';

const getAllGeneric = (endpoint: string) => async (): Promise<CompensationRequest[]> => {
  const {data} = await intranetApi.get(endpoint);

  return array(compensationRequestSchema).runWithException(data);
};

const getAllDefault = getAllGeneric('/v1/compensations/requests/available');
const getAllAsAdmin = getAllGeneric('/v1/compensations/admin/requests/getAll');

export const getAll = createPermissionDependentHandler(
  [{permission: Permission.COMPENSATION_READ_ALL, handler: getAllAsAdmin}],
  getAllDefault,
);
