import {StyledLink} from 'components/ui/StyledLink';
import {CycleLite} from 'domain/assessment/cycle/model/lite';
import {CycleStatus} from 'domain/assessment/cycle/model/status';
import React from 'react';
import {assessmentUrls} from 'routes/assessment/urls';

type Props = {
  cycle: CycleLite;
};

export function CycleLink({cycle: {id, name, status}}: Props) {
  if (status === CycleStatus.DRAFT || status === CycleStatus.ARCHIVED) {
    return <>{name}</>;
  }

  return (
    <StyledLink to={assessmentUrls.cycle({id})} colored>
      {name}
    </StyledLink>
  );
}
