import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {ClientErrorStatus} from 'apiClient/ClientError';
import {EntityResponseStatus} from 'apiClient/entityResponse';
import {CompensationRequest, CompensationRequestId} from 'domain/compensations/request/model';
import {createStore} from 'effector';
import {
  approveRequestAsAdminFx,
  approveRequestAsManagerFx,
  cancelRequestFx,
  closeRequestFx,
  createRequestFx,
  getAllRequestsFx,
  getRequestByIdFx,
  rejectRequestFx,
  submitReportFx,
  updateCommentFromAdminFx,
  updateReportFx,
} from '.';

type State = {
  requestIds: CompensationRequestId[];
  requestsById: Record<CompensationRequestId, CompensationRequest>;
  requestsState: DataState;
  requestStatesById: Record<CompensationRequestId, DataState>;
  requestErrorsById: Record<CompensationRequestId, ClientErrorStatus>;
};

export const $compensationRequests = createStore<State>({
  requestIds: [],
  requestsById: {},
  requestsState: DataState.IDLE,
  requestStatesById: {},
  requestErrorsById: {},
})
  .on(getAllRequestsFx, (state) => {
    return {
      ...state,
      requestsState: DataState.LOADING,
    };
  })
  .on(getAllRequestsFx.fail, (state) => {
    return {
      ...state,
      requestsById: {},
      requestsState: DataState.FAILED,
    };
  })
  .on(getAllRequestsFx.done, (state, {result}) => {
    return {
      ...state,
      requestIds: result.map(({id}) => id),
      requestsById: arrayToObject(result, ({id}) => id),
      requestStatesById: arrayToObject(
        result,
        ({id}) => id,
        () => DataState.LOADED,
      ),
      requestsState: DataState.LOADED,
    };
  })
  .on(createRequestFx.done, (state, {result}) => {
    return {
      ...state,
      requestIds: [...state.requestIds, result.id],
      requestsById: {
        ...state.requestsById,
        [result.id]: result,
      },
    };
  })
  .on(getRequestByIdFx, (state, id) => {
    return {
      ...state,
      requestStatesById: {
        ...state.requestStatesById,
        [id]: DataState.LOADING,
      },
    };
  })
  .on(getRequestByIdFx.fail, (state, {params: id}) => {
    return {
      ...state,
      requestsById: omit(state.requestsById, [id]),
      requestErrorsById: omit(state.requestErrorsById, [id]),
      requestStatesById: {
        ...state.requestStatesById,
        [id]: DataState.FAILED,
      },
    };
  })
  .on(getRequestByIdFx.done, (state, {params: id, result}) => {
    if (result.status === EntityResponseStatus.REJECTED) {
      return {
        ...state,
        requestsById: omit(state.requestsById, [id]),
        requestStatesById: {
          ...state.requestStatesById,
          [id]: DataState.IDLE,
        },
        requestErrorsById: {
          ...state.requestErrorsById,
          [id]: result.reason,
        },
      };
    }
    return {
      ...state,
      requestsById: {
        ...state.requestsById,
        [id]: result.value,
      },
      requestErrorsById: omit(state.requestErrorsById, [id]),
      requestStatesById: {
        ...state.requestStatesById,
        [id]: DataState.LOADED,
      },
    };
  })
  .on(
    [
      approveRequestAsAdminFx.doneData,
      approveRequestAsManagerFx.doneData,
      cancelRequestFx.doneData,
      closeRequestFx.doneData,
      rejectRequestFx.doneData,
      submitReportFx.doneData,
      updateCommentFromAdminFx.doneData,
      updateReportFx.doneData,
    ],
    (state, result) => {
      return {
        ...state,
        requestsById: {
          ...state.requestsById,
          [result.id]: result,
        },
      };
    },
  );
