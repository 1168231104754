import {defineMessages} from 'react-intl';

export const navItems = defineMessages({
  assessment: {
    defaultMessage: 'Review (Admin)',
    description: 'Side navigation item',
  },
  staff: {
    defaultMessage: 'Joomers',
    description: 'Side navigation item',
  },
  options: {
    defaultMessage: 'Options',
    description: 'Side navigation item',
  },
  merch: {
    defaultMessage: 'Merch',
    description: 'Side navigation item',
  },
  officeMap: {
    defaultMessage: 'Office map',
    description: 'Side navigation item',
  },
  offers: {
    defaultMessage: 'Offers',
    description: 'Side navigation item',
  },
  rtconf: {
    defaultMessage: 'Runtime config',
    description: 'Side navigation item',
  },
  compensations: {
    defaultMessage: 'Business trips',
    description: 'Side navigation item',
  },
  legalEntities: {
    defaultMessage: 'Legal entities',
    description: 'Side navigation item',
  },
  hrConsole: {
    defaultMessage: 'HR console',
    description: 'Side navigation item',
  },
  managerConsole: {
    defaultMessage: 'Manager console',
    description: 'Side navigation item',
  },
  roles: {
    defaultMessage: 'User roles',
    description: 'Side navigation item',
  },
  secureRoles: {
    defaultMessage: 'Financial roles',
    description: 'Side navigation item',
  },
  jdm: {
    defaultMessage: 'JDM',
    description: 'Side navigation item',
  },
  redirector: {
    defaultMessage: 'Redirector',
    description: 'Side navigation item',
  },
  timeOffRequests: {
    defaultMessage: 'Time off',
    description: 'Side navigation item',
  },
  birthdaysCalendar: {
    defaultMessage: 'Birthdays calendar',
    description: 'Side navigation item',
  },
  timeOffCalendar: {
    defaultMessage: '“Who’s out” calendar',
    description: 'Side navigation item',
  },
  sensitiveData: {
    defaultMessage: 'Sensitive data',
    description: 'Side navigation item',
  },
  officeCare: {
    defaultMessage: 'Office Care portal',
    description: 'Side navigation item',
  },
  learningAndDevelopment: {
    defaultMessage: 'Learning and development',
    description: 'Side navigation item',
  },
  accessCenter: {
    defaultMessage: 'Access center',
    description: 'Side navigation item',
  },
});

export const confirmationDialog = defineMessages({
  title: {
    defaultMessage: 'Sensitive data may be displayed',
    description: 'Options confirmation dialog title',
  },
  ownOptionsWarning: {
    defaultMessage: 'Warning! If you follow this link, your options data will be displayed.',
    description: 'Options confirmation dialog text',
  },
  othersOptionsWarning: {
    defaultMessage: 'Warning! If you follow this link, joomers’ options data will be displayed.',
    description: 'Options confirmation dialog text',
  },
  button: {
    defaultMessage: 'Open Options',
    description: '[button] Open Options link button',
  },
});

export const messages = defineMessages({
  externalLinksTitle: {
    defaultMessage: 'Useful links',
    description: '[title] Side navigation group title',
  },
  calendarsTitle: {
    defaultMessage: 'Google calendars',
    description: '[title] Side navigation group title',
  },
  newBadge: {
    defaultMessage: 'new',
    description: 'Side navigation item: new content badge',
  },
});
