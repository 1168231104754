import {Duty} from 'domain/duty/model';
import {UserId} from 'domain/user/model';

type Options = {
  duty: Duty;
  selfUserId: UserId | undefined;
};

export function checkIsDutyMember({duty, selfUserId}: Options) {
  return duty.rotationSettings.rotation.flatMap(({users}) => users).some(({id}) => id === selfUserId);
}
