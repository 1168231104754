import {Panel} from '@joomcode/joom-ui/Panel';
import {Avatar} from 'components/ui/Avatar';
import {Timing} from 'domain/assessment/common/widgets/Timing';
import {UserQuestionnaire} from 'domain/assessment/questionnaire/model/userQuestionnaire';
import {QuestionnaireStatusBadge} from 'domain/assessment/questionnaire/widgets/StatusBadge';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages, roles} from './messages';
import styles from './styles.css';

type Props = {
  questionnaire: UserQuestionnaire;
};

export function QuestionnaireMetaPanel({questionnaire: {status, reviewee, responderRole, accessEndAt}}: Props) {
  const intl = useIntl();

  return (
    <Panel withPadding>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
          <QuestionnaireStatusBadge status={status} />
        </div>
        <div className={styles.user}>
          <div className={styles.avatar}>
            <Avatar imageBundle={reviewee.avatarBundle} size='s' name={reviewee.fullName} />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userLink}>
              <UserLink user={reviewee} withAvatar={false} />
            </div>
            <div className={styles.role}>{intl.formatMessage(roles[responderRole.id])}</div>
          </div>
        </div>
        <Timing end={accessEndAt} />
      </div>
    </Panel>
  );
}
