import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  legalEntityId: {
    defaultMessage: 'Legal entities',
    description: 'Compensation request filter label',
  },
  showIndirect: {
    defaultMessage: 'Show indirect',
    description: 'Compensation request filter label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Compensation request filter label',
  },
});
