import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import faker from 'faker';
import {ParticipantRole, ParticipantRoleId} from '.';

export function mockParticipantRole(data: Partial<ParticipantRole> = {}): ParticipantRole {
  return {
    id: faker.random.arrayElement(getEnumValues(ParticipantRoleId)),
    isReviewee: faker.random.boolean(),
    name: faker.company.bsNoun(),
    ...data,
  };
}
