import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {useQuestionnaireNavigation} from 'domain/assessment/questionnaire/hooks/useQuestionnaireNavigation';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function QuestionnaireSubmitPanel() {
  const intl = useIntl();
  const {setNextPage, setPreviousPage, isFirstPage, isLastPage} = useQuestionnaireNavigation();

  return (
    <SubmitFormPanel>
      <ButtonGroup spaced size='l'>
        {!isFirstPage && (
          <Button kind='secondary' intent='primary' type='button' onClick={setPreviousPage}>
            {intl.formatMessage(messages.buttonBack)}
          </Button>
        )}
        {!isLastPage && (
          <Button kind='primary' intent='primary' type='button' onClick={setNextPage}>
            {intl.formatMessage(messages.buttonNext)}
          </Button>
        )}
        {isLastPage && (
          <Button kind='primary' intent='primary' type='submit' disabled>
            {intl.formatMessage(messages.buttonSubmit)}
          </Button>
        )}
      </ButtonGroup>
    </SubmitFormPanel>
  );
}
