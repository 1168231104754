import {array, ExtractSchemaType, intersection, object} from '@joomcode/deprecated-utils/jsonValidation';
import {cycleLiteSchema} from 'domain/assessment/cycle/model/lite';
import {participantCountSchema} from 'domain/assessment/cycle/model/participantCount';

export const listedCycleSchema = intersection(
  cycleLiteSchema,
  object({
    participantCounts: array(participantCountSchema),
  }),
);

export type ListedCycle = ExtractSchemaType<typeof listedCycleSchema>;
