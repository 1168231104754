import {Direction, type useScrollbox} from '@joomcode/joom-ui/Timeline';
import React from 'react';
import type {DutyTimelineSize} from '../types';
import {ScrollButton} from './ScrollButton';
import {SelectSize} from './SelectSize';
import styles from './styles.css';

type PropsType = {
  scrollboxApi: ReturnType<typeof useScrollbox>[1];
  size: DutyTimelineSize;
  sizes: DutyTimelineSize[];
  onSizeChange: (size: DutyTimelineSize) => void;
};

export function Controls({sizes, size, onSizeChange, scrollboxApi: {isScrollableTo, scrollToDirection}}: PropsType) {
  return (
    <div className={styles.Controls}>
      <div className={styles.scroll}>
        <ScrollButton
          direction={Direction.LEFT}
          disabled={!isScrollableTo(Direction.LEFT)}
          onClick={() => scrollToDirection(Direction.LEFT)}
        />
        <ScrollButton
          direction={Direction.RIGHT}
          disabled={!isScrollableTo(Direction.RIGHT)}
          onClick={() => scrollToDirection(Direction.RIGHT)}
        />
      </div>
      <SelectSize sizes={sizes} value={size} onChange={onSizeChange} />
    </div>
  );
}
