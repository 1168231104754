import {Duty} from 'domain/duty/model';
import {useCallback, useMemo, useState} from 'react';

function getPendingChangeDate(duty: Duty | undefined): number | undefined {
  if (!duty) {
    return undefined;
  }

  const {effectiveDate, handoffMoment} = duty.rotationSettings;
  const {time} = handoffMoment;
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const date = new Date(effectiveDate);
  const pendingChangeDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    hours,
    minutes,
    seconds,
  );

  return Date.now() < pendingChangeDate &&
    duty.schedule.main.some(({startDate}) => new Date(startDate).getTime() < pendingChangeDate)
    ? pendingChangeDate
    : undefined;
}

type ReturnShape = {
  pendingChangeDate: number | undefined;
  showPendingChangeDialog: boolean;
  onPendingChangeDialogClose: () => void;
};

export function usePendingChange(duty: Duty | undefined): ReturnShape {
  const pendingChangeDate = useMemo(() => getPendingChangeDate(duty), [duty]);
  const [showPendingChangeDialog, setShowPendingChangeDialog] = useState(Boolean(pendingChangeDate));
  const onPendingChangeDialogClose = useCallback(() => setShowPendingChangeDialog(false), [setShowPendingChangeDialog]);

  return {
    pendingChangeDate,
    showPendingChangeDialog,
    onPendingChangeDialogClose,
  };
}
